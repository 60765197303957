import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, getUserData, updateUserSubscription } from '../firebase';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Swal from 'sweetalert2';

const stripePromise = loadStripe('your_stripe_publishable_key');

const SubscriptionForm = () => {
    const [loading, setLoading] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            console.error('[error]', error);
            Swal.fire('Error', error.message, 'error');
            setLoading(false);
            return;
        }

        const user = auth.currentUser;
        if (!user) {
            Swal.fire('Error', 'You must be logged in to subscribe', 'error');
            setLoading(false);
            return;
        }

        try {
            // Call your backend API to create a subscription
            const response = await fetch('https://your-backend-api.com/create-subscription', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    paymentMethodId: paymentMethod.id,
                    userId: user.uid,
                }),
            });

            const subscription = await response.json();

            if (subscription.status === 'active') {
                await updateUserSubscription(user.uid, {
                    isSubscribed: true,
                    subscriptionStatus: 'active',
                    stripeCustomerId: subscription.customer,
                });

                Swal.fire('Success', 'Your subscription is now active!', 'success');
                navigate('/account');
            } else {
                throw new Error('Subscription failed');
            }
        } catch (error) {
            console.error('Error:', error);
            Swal.fire('Error', 'Failed to process subscription', 'error');
        } finally {
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <CardElement />
            <button type="submit" disabled={!stripe || loading} className="btn btn-primary mt-4">
                {loading ? 'Processing...' : 'Subscribe Now'}
            </button>
        </form>
    );
};

const SubscriptionPage = () => {
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        const fetchUserData = async () => {
            const user = auth.currentUser;
            if (user) {
                const data = await getUserData(user.uid);
                setUserData(data);
            }
        };

        fetchUserData();
    }, []);

    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-6">Subscribe to Our Service</h1>
            {userData && (
                <div className="mb-6">
                    <p>Current Status: {userData.subscriptionStatus}</p>
                    {userData.trialEndDate && (
                        <p>Trial Ends: {userData.trialEndDate.toDate().toLocaleDateString()}</p>
                    )}
                </div>
            )}
            <Elements stripe={stripePromise}>
                <SubscriptionForm />
            </Elements>
        </div>
    );
};

export default SubscriptionPage;