import React, {useContext, useEffect, useState} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import {collection, getDocs, query, where} from 'firebase/firestore';
import {db} from '../firebase';
import {Link} from 'react-router-dom';
import {ChevronLeft, ChevronRight, Mail, MapPin, Phone, Star} from 'lucide-react';
import LanguageContext from "./LanguageContext";
import '../App.css';
import OpeningTimeList from "./OpeningTimeList";
import ReviewDialog from "./ReviewDialog";
import ReviewTrigger from "./ReviewDialog";
// Simple translations object
const translations = {
    en: {
        loading: "Loading...",
        error: "Failed to fetch barbers. Please try again later.",
        noImageAvailable: "No image available",
        prev: "Prev",
        next: "Next",
        address: "Address:",
        phone: "Phone:",
        email: "Email:",
        biography: "Biography:",
        noBiographyAvailable: "No biography available",
        createdAt: "Created At:",
        availability: "Availability:",
        noAvailabilityInfo: "No availability information",
        noAvailableHoursSet: "No available hours set",
        services: "Services:",
        bookNow: "Book Now"
    },
    tr: {
        loading: "Yükleniyor...",
        error: "Berberler getirilemedi. Lütfen daha sonra tekrar deneyin.",
        noImageAvailable: "Resim mevcut değil",
        prev: "Önceki",
        next: "Sonraki",
        address: "Adres:",
        phone: "Telefon:",
        email: "E-posta:",
        biography: "Biyografi:",
        noBiographyAvailable: "Biyografi mevcut değil",
        createdAt: "Oluşturulma Tarihi:",
        availability: "Müsaitlik:",
        noAvailabilityInfo: "Müsaitlik bilgisi yok",
        noAvailableHoursSet: "Müsait saat ayarlanmamış",
        services: "Hizmetler:",
        bookNow: "Şimdi Rezervasyon Yap"
    },
    ar: {
        loading: "جاري التحميل...",
        error: "فشل في جلب الحلاقين. يرجى المحاولة مرة أخرى لاحقًا.",
        noImageAvailable: "لا تتوفر صورة",
        prev: "السابق",
        next: "التالي",
        address: "العنوان:",
        phone: "الهاتف:",
        email: "البريد الإلكتروني:",
        biography: "السيرة الذاتية:",
        noBiographyAvailable: "لا تتوفر سيرة ذاتية",
        createdAt: "تم الإنشاء في:",
        availability: "التوفر:",
        noAvailabilityInfo: "لا توجد معلومات عن التوفر",
        noAvailableHoursSet: "لم يتم تعيين ساعات متاحة",
        services: "الخدمات:",
        bookNow: "احجز الآن"
    },
    de: {
        loading: "Wird geladen...",
        error: "Friseure konnten nicht abgerufen werden. Bitte versuchen Sie es später erneut.",
        noImageAvailable: "Kein Bild verfügbar",
        prev: "Zurück",
        next: "Weiter",
        address: "Adresse:",
        phone: "Telefon:",
        email: "E-Mail:",
        biography: "Biografie:",
        noBiographyAvailable: "Keine Biografie verfügbar",
        createdAt: "Erstellt am:",
        availability: "Verfügbarkeit:",
        noAvailabilityInfo: "Keine Verfügbarkeitsinformationen",
        noAvailableHoursSet: "Keine verfügbaren Stunden festgelegt",
        services: "Dienstleistungen:",
        bookNow: "Jetzt buchen"
    }
};

const BarberList = () => {
    const [barbers, setBarbers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentImageIndices, setCurrentImageIndices] = useState({});
    const [shopRatings, setShopRatings] = useState({});
    const {language} = useContext(LanguageContext);
    const t = translations[language] || translations.en;


    // Fetch barbers and their ratings
        useEffect(() => {
            const fetchBarbersAndRatings = async () => {
                try {
                    // Fetch barbers
                    const barbersCollection = collection(db, 'barberShops');
                    const barberSnapshot = await getDocs(barbersCollection);
                    const barberList = barberSnapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data()
                    }));

                    // Fetch ratings for all shops
                    const ratingsCollection = collection(db, 'ratings');
                    const ratingsSnapshot = await getDocs(
                        query(ratingsCollection, where('status', '==', 'active'))
                    );

                    // Calculate ratings for each shop
                    const ratings = {};
                    ratingsSnapshot.docs.forEach(doc => {
                        const ratingData = doc.data();
                        const shopId = ratingData.shopId;

                        if (!ratings[shopId]) {
                            ratings[shopId] = {
                                totalRating: 0,
                                count: 0,
                                distribution: {1: 0, 2: 0, 3: 0, 4: 0, 5: 0}
                            };
                        }

                        ratings[shopId].totalRating += ratingData.rating;
                        ratings[shopId].count += 1;
                        ratings[shopId].distribution[ratingData.rating] += 1;
                    });

                    // Calculate averages and percentages
                    Object.keys(ratings).forEach(shopId => {
                        const shopRating = ratings[shopId];
                        shopRating.averageRating = (shopRating.totalRating / shopRating.count).toFixed(1);

                        // Calculate percentage for each star rating
                        Object.keys(shopRating.distribution).forEach(stars => {
                            shopRating.distribution[stars] =
                                ((shopRating.distribution[stars] / shopRating.count) * 100).toFixed(1);
                        });
                    });

                    setShopRatings(ratings);
                    setBarbers(barberList);

                    // Set initial image indices
                    const initialIndices = {};
                    barberList.forEach(barber => {
                        initialIndices[barber.id] = 0;
                    });
                    setCurrentImageIndices(initialIndices);

                    setLoading(false);
                } catch (error) {
                    console.error("Error fetching data: ", error);
                    setError("Failed to fetch data");
                    setLoading(false);
                }
            };

            fetchBarbersAndRatings();
        }, []);

    useEffect(() => {
        const fetchBarbers = async () => {
            try {
                const barbersCollection = collection(db, 'barberShops');
                const barberSnapshot = await getDocs(barbersCollection);
                const barberList = barberSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                setBarbers(barberList);
                const initialIndices = {};
                barberList.forEach(barber => {
                    initialIndices[barber.id] = 0;
                });
                setCurrentImageIndices(initialIndices);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching barbers: ", error);
                setError("Failed to fetch barbers");
                setLoading(false);
            }
        };

        fetchBarbers();
    }, []);

    const toggleImage = (barberId, direction) => {
        setCurrentImageIndices(prevIndices => {
            const currentIndex = prevIndices[barberId];
            const imageCount = barbers.find(b => b.id === barberId).imageUrls.length;
            let newIndex;
            if (direction === 'next') {
                newIndex = (currentIndex + 1) % imageCount;
            } else {
                newIndex = (currentIndex - 1 + imageCount) % imageCount;
            }
            return {...prevIndices, [barberId]: newIndex};
        });
    };

    const handlePhoneClick = (phoneNumber) => {
        window.location.href = `tel:${phoneNumber}`;
    };

    const handleEmailClick = (email) => {
        window.location.href = `mailto:${email}`;
    };

    const formatAvailability = (availability) => {
        if (!availability || typeof availability !== 'object') {
            return t.noAvailability;
        }
        return Object.entries(availability)
            .filter(([_, hours]) => hours?.open && hours?.close)
            .map(([day, hours]) => `${day}: ${hours.open}-${hours.close}`)
            .join(', ');
    };

        const renderRatingStars = (shopId) => {
            const shopRating = shopRatings[shopId];
            const rating = shopRating ? parseFloat(shopRating.averageRating) : 0;
            const reviewCount = shopRating ? shopRating.count : 0;

            return (
                <div className="flex items-center gap-2">
                    <div className="flex items-center gap-0.5">
                        {[...Array(5)].map((_, i) => (
                            <Star
                                key={i}
                                className={`w-4 h-4 ${
                                    i < Math.round(rating)
                                        ? 'text-yellow-400 fill-yellow-400'
                                        : 'text-base-300'
                                }`}
                            />
                        ))}
                    </div>
                    {reviewCount > 0 && (
                        <span className="text-sm text-base-content/70">
                        ({rating} • {reviewCount} {reviewCount === 1 ? 'review' : 'reviews'})
                    </span>
                    )}
                </div>
            );
        };

    return (
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            className="w-full"
        >
            {loading ? (
                <div className="flex justify-center items-center h-64">
                    <div className="loading loading-spinner loading-lg text-primary"></div>
                </div>
            ) : error ? (
                <div className="text-center p-4 bg-error/10 text-error rounded-lg">
                    {error}
                </div>
            ) : (
                <motion.div
                    layout
                    className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
                >
                    <AnimatePresence>
                        {barbers.map((barber) => (
                            <motion.div
                                key={barber.id}
                                layout
                                initial={{opacity: 0, y: 20}}
                                animate={{opacity: 1, y: 0}}
                                exit={{opacity: 0, y: -20}}
                                className="rounded-xl bg-base-100 shadow-sm hover:shadow-lg
         transition-all duration-300 overflow-hidden border border-base-300
         hover:border-base-content/10"
                                whileHover={{y: -4}}
                            >
                                {/* Image Section */}
                                <div className="relative h-56 overflow-hidden">
                                    <motion.img
                                        src={barber.imageUrls[currentImageIndices[barber.id]]}
                                        alt={barber.name}
                                        className="w-full h-full object-cover"
                                    />
                                    {barber.imageUrls?.length > 1 && (
                                        <div className="absolute inset-x-0 bottom-0 flex justify-between p-2">
                                            <motion.button
                                                whileHover={{scale: 1.1}}
                                                whileTap={{scale: 0.9}}
                                                onClick={() => toggleImage(barber.id, 'prev')}
                                                className="p-2 rounded-full bg-black/40 hover:bg-black/60 transition-colors"
                                            >
                                                <ChevronLeft className="w-5 h-5 text-white"/>
                                            </motion.button>
                                            <motion.button
                                                whileHover={{scale: 1.1}}
                                                whileTap={{scale: 0.9}}
                                                onClick={() => toggleImage(barber.id, 'next')}
                                                className="p-2 rounded-full bg-black/40 hover:bg-black/60 transition-colors"
                                            >
                                                <ChevronRight className="w-5 h-5 text-white"/>
                                            </motion.button>
                                        </div>
                                    )}
                                </div>

                                {/* Content Section */}
                                <div className="p-5 space-y-4">
                                    <div className="flex justify-between items-start">
                                        <div className="space-y-1">
                                            <h2 className="text-xl font-semibold text-base-content">
                                                {barber.name}
                                            </h2>
                                            {renderRatingStars(barber.id)}
                                            <ReviewTrigger
                                                shopId={barber.id}
                                                initialRating={shopRatings[barber.id]?.averageRating || 0}
                                                reviewCount={shopRatings[barber.id]?.count || 0}
                                                distribution={shopRatings[barber.id]?.distribution}
                                            />
                                        </div>

                                        <Link to={`/book/${barber.id}`}>
                                            <motion.button
                                                whileHover={{scale: 1.02}}
                                                whileTap={{scale: 0.98}}
                                                className="btn btn-sm bg-primary hover:bg-primary/90 dark:bg-primary/90
                dark:hover:bg-primary text-primary-content shadow-sm hover:shadow-md
                transition-all duration-300 border-none font-medium rounded-full px-4"
                                            >
                                                {t.bookNow}
                                            </motion.button>
                                        </Link>
                                    </div>

                                    <div className="grid grid-cols-2 gap-4">
                                        <div className="space-y-2">
                                            <motion.button
                                                whileHover={{scale: 1.02}}
                                                onClick={() => handlePhoneClick(barber.phoneNumber)}
                                                className="flex items-center gap-2 text-base-content/70 hover:text-primary
                        transition-colors w-full"
                                            >
                                                <Phone className="w-4 h-4"/>
                                                <span className="text-sm">{barber.phoneNumber}</span>
                                            </motion.button>

                                            <motion.button
                                                whileHover={{scale: 1.02}}
                                                onClick={() => handleEmailClick(barber.email)}
                                                className="flex items-center gap-2 text-base-content/70 hover:text-primary
                        transition-colors w-full"
                                            >
                                                <Mail className="w-4 h-4"/>
                                                <span className="text-sm truncate">{barber.email}</span>
                                            </motion.button>

                                            <div className="flex items-center gap-2 text-base-content/70">
                                                <MapPin className="w-4 h-4 flex-shrink-0"/>
                                                <span className="text-sm truncate">{barber.address}</span>
                                            </div>
                                        </div>

                                        <div className="space-y-2">
                                            {barber.services?.slice(0, 3).map((service, index) => (
                                                <motion.div
                                                    key={index}
                                                    initial={{opacity: 0, x: 20}}
                                                    animate={{opacity: 1, x: 0}}
                                                    transition={{delay: index * 0.1}}
                                                    className="flex justify-between text-sm text-base-content/70"
                                                >
                                                    <span className="truncate">{service.name}</span>
                                                    <span className="font-medium">${service.price}</span>
                                                </motion.div>
                                            ))}
                                        </div>
                                    </div>

                                    <OpeningTimeList shop={barber}/>
                                </div>
                            </motion.div>
                        ))}
                    </AnimatePresence>
                </motion.div>
            )}
        </motion.div>
    );
};

export default BarberList;