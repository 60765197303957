import { motion } from 'framer-motion';
import { Clock } from 'lucide-react';

const OpeningTimeList = ({ shop }) => {
    const daysOrder = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const today = new Date().toLocaleString('en-US', { weekday: 'long' });

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="bg-base-300/30 rounded-lg p-3"
        >
            <div className="flex items-center gap-2 mb-2 text-primary">
                <Clock className="w-4 h-4" />
                <span className="text-sm font-medium">Opening Hours</span>
            </div>

            <div className="grid grid-cols-2 sm:grid-cols-3 gap-1.5 text-xs">
                {daysOrder.map((day, index) => {
                    const dayData = shop.availability?.[day];
                    const isOpen = dayData?.open && dayData?.close;
                    const isToday = day === today;

                    return (
                        <motion.div
                            key={day}
                            initial={{ x: -10, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            transition={{ delay: index * 0.05 }}
                            className={`
                flex items-center gap-1.5 p-1.5 rounded
                ${isToday ? 'bg-primary/10 ring-1 ring-primary/20' : ''}
                ${isOpen ? 'text-base-content' : 'text-base-content/50'}
              `}
                        >
                            <div className={`
                w-1.5 h-1.5 rounded-full
                ${isOpen ? 'bg-success animate-pulse' : 'bg-error/50'}
              `} />
                            <span className="min-w-[40px]">{day.slice(0, 3)}</span>
                            <span className="font-medium">
                {isOpen ? `${dayData.open}-${dayData.close}` : 'Closed'}
              </span>
                        </motion.div>
                    );
                })}
            </div>
        </motion.div>
    );
};

export default OpeningTimeList;