import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const Particle = () => {
    const randomStart = () => ({
        x: Math.random() * window.innerWidth,
        y: Math.random() * window.innerHeight,
        scale: Math.random() * 0.5 + 0.5,
        opacity: Math.random() * 0.4 + 0.1
    });

    const [position, setPosition] = useState(randomStart());

    useEffect(() => {
        const duration = 15000 + Math.random() * 10000;
        const animate = () => {
            const newPosition = randomStart();
            setPosition(newPosition);
            setTimeout(animate, duration);
        };

        setTimeout(animate, duration);
    }, []);

    return (
        <motion.div
            className="absolute w-2 h-2 bg-white rounded-full pointer-events-none"
            animate={{
                x: [position.x, position.x + (Math.random() - 0.5) * 100],
                y: [position.y, position.y + (Math.random() - 0.5) * 100],
                scale: [position.scale, position.scale * 1.2],
                opacity: [position.opacity, 0]
            }}
            transition={{
                duration: 10,
                ease: "linear",
                repeat: Infinity,
                repeatType: "reverse"
            }}
        />
    );
};

const ParticleField = () => {
    return (
        <div className="fixed inset-0 pointer-events-none z-10">
            {Array.from({ length: 50 }).map((_, i) => (
                <Particle key={i} />
            ))}
        </div>
    );
};

export default ParticleField;