import React, {useContext, useEffect, useState} from 'react';
import {auth, db, storage} from '../firebase';
import {collection, deleteDoc, doc, getDoc, getDocs, query, serverTimestamp, setDoc, where} from 'firebase/firestore';
import {deleteObject, getDownloadURL, ref, uploadBytes} from 'firebase/storage';
import {useNavigate} from 'react-router-dom';
import useStore from '../store';
import Swal from 'sweetalert2';
import LanguageContext from "./LanguageContext";
import {
    deleteUser,
    EmailAuthProvider,
    reauthenticateWithCredential,
    sendPasswordResetEmail,
    updateEmail,
    updateProfile
} from 'firebase/auth';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {Camera, Clock, Copy, Edit2, Edit3, Link as LinkIcon, MapPin, Plus, Save, Trash2, X} from 'lucide-react';
import EditBarberShopModal from "./EditBarberShopModal";
import EditAvailabilityModal from "./EditAvailabilityModal";
import AvailabilityAccordion from "./AvailabilityDisplay";
import CopyLinkButton from "./CopyLinkButton";

const translations = {
    en: {
        changePhoto: "Change Photo",
        cancelPhotoChange: "Cancel Photo Change",
        profileUpdated: "Profile updated successfully",
        phone: "Phone Number: ",
        loading: "Loading...",
        pleaseLogIn: "Please log in to view your account.",
        myAccount: "My Account",
        accountInfo: "Account Information",
        name: "Name:",
        email: "Email:",
        myBarberShops: "My Barber Shops",
        address: "Address:",
        services: "Services",
        availability: "Availability",
        shopImages: "Shop Images",
        uniqueShopLink: "Unique Shop Link",
        deleteShop: "Delete Shop",
        noShops: "You haven't created any barber shops yet.",
        createShop: "Create Barber Shop",
        copied: "Copied!",
        copiedText: "The shop link has been copied to your clipboard.",
        areYouSure: "Are you sure?",
        cantRevert: "You won't be able to revert this!",
        yesDelete: "Yes, delete it!",
        success: "Success",
        shopDeleted: "Your barber shop has been deleted.",
        error: "Error",
        deleteAccount: "Delete Account",
        deleteAccountWarning: "Are you absolutely sure you want to delete your account?",
        deleteAccountDescription: "This will permanently delete your account, all your barber shops, and all associated data. This action cannot be undone.",
        subscriptionWarning: "IMPORTANT: If you have an active subscription, please make sure to cancel it first from your subscription settings to avoid further charges.",
        enterPassword: "Please enter your password to confirm deletion:",
        // googleAuthNote: "As you're signed in with Google, you can delete your account directly without password confirmation.",
        passwordRequired: "Password is required to delete account",
        accountDeleted: "Your account has been successfully deleted",
        errorDeletingAccount: "Error deleting account. Please try again.",
        cancel: "Cancel",
        confirm: "Confirm Delete",
        confirmEmail: "Confirm Account Deletion",
        enterEmailToConfirm: "Please enter your email address to confirm deletion",
        emailDoesNotMatch: "Email address does not match your account email",
        authenticationFailed: "Authentication failed. Please try again.",
        processing: "Processing...",
        resetPasswordFirst: "Reset Password First",
        resetPasswordDescription: "To delete your account, you need to first reset your password. We'll send you a reset link.",
        resetPasswordSent: "Password reset email has been sent. Please check your inbox and try deleting your account again after resetting.",
    },
    tr: {
        changePhoto: "Fotoğrafı Değiştir",
        cancelPhotoChange: "Fotoğraf Değişimini İptal Et",
        profileUpdated: "Profil başarıyla güncellendi",
        phone: "Telefon Numara",
        loading: "Yükleniyor...",
        pleaseLogIn: "Hesabınızı görüntülemek için lütfen giriş yapın.",
        myAccount: "Hesabım",
        accountInfo: "Hesap Bilgileri",
        name: "İsim:",
        email: "E-posta:",
        myBarberShops: "Berber Dükkanlarım",
        address: "Adres:",
        services: "Hizmetler",
        availability: "Müsaitlik",
        shopImages: "Dükkan Görselleri",
        uniqueShopLink: "Benzersiz Dükkan Linki",
        deleteShop: "Dükkanı Sil",
        noShops: "Henüz bir berber dükkanı oluşturmadınız.",
        createShop: "Berber Dükkanı Oluştur",
        copied: "Kopyalandı!",
        copiedText: "Dükkan linki panonuza kopyalandı.",
        areYouSure: "Emin misiniz?",
        cantRevert: "Bu işlemi geri alamazsınız!",
        yesDelete: "Evet, sil!",
        success: "Başarılı",
        shopDeleted: "Berber dükkanınız silindi.",
        error: "Hata",
        deleteAccount: "Hesabı Sil",
        deleteAccountWarning: "Hesabınızı silmek istediğinizden emin misiniz?",
        deleteAccountDescription: "Bu işlem hesabınızı, tüm berber dükkanlarınızı ve ilgili tüm verileri kalıcı olarak silecektir. Bu işlem geri alınamaz.",
        subscriptionWarning: "ÖNEMLİ: Aktif bir aboneliğiniz varsa, ek ücretlendirmelerden kaçınmak için lütfen önce abonelik ayarlarınızdan iptal ettiğinizden emin olun.",
        enterPassword: "Silme işlemini onaylamak için lütfen şifrenizi girin:",
        // googleAuthNote: "Google ile giriş yaptığınız için, hesabınızı şifre onayı olmadan doğrudan silebilirsiniz.",
        passwordRequired: "Hesabı silmek için şifre gereklidir",
        accountDeleted: "Hesabınız başarıyla silindi",
        errorDeletingAccount: "Hesap silinirken hata oluştu. Lütfen tekrar deneyin.",
        cancel: "İptal",
        confirm: "Silmeyi Onayla",
        resetPasswordFirst: "Önce Şifreyi Sıfırla",
        resetPasswordDescription: "Hesabınızı silmek için önce şifrenizi sıfırlamanız gerekiyor. Size bir sıfırlama bağlantısı göndereceğiz.",
        resetPasswordSent: "Şifre sıfırlama e-postası gönderildi. Lütfen gelen kutunuzu kontrol edin ve sıfırladıktan sonra hesabınızı silmeyi tekrar deneyin.",
    },
    ar: {
        phone: "رقم الهاقف",
        loading: "جاري التحميل...",
        pleaseLogIn: "الرجاء تسجيل الدخول لعرض حسابك.",
        myAccount: "حسابي",
        accountInfo: "معلومات الحساب",
        name: "الاسم:",
        email: "البريد الإلكتروني:",
        myBarberShops: "محلات الحلاقة الخاصة بي",
        address: "العنوان:",
        services: "الخدمات",
        availability: "التوفر",
        shopImages: "صور المحل",
        uniqueShopLink: "رابط المحل الفريد",
        deleteShop: "حذف المحل",
        noShops: "لم تقم بإنشاء أي محل حلاقة بعد.",
        createShop: "إنشاء محل حلاقة",
        copied: "تم النسخ!",
        copiedText: "تم نسخ رابط المحل إلى الحافظة.",
        areYouSure: "هل أنت متأكد؟",
        cantRevert: "لن تتمكن من التراجع عن هذا!",
        yesDelete: "نعم، احذفه!",
        success: "نجاح",
        shopDeleted: "تم حذف محل الحلاقة الخاص بك.",
        error: "خطأ",
        deleteAccount: "حذف الحساب",
        deleteAccountWarning: "هل أنت متأكد تماماً من رغبتك في حذف حسابك؟",
        deleteAccountDescription: "سيؤدي هذا إلى حذف حسابك وجميع محلات الحلاقة الخاصة بك وجميع البيانات المرتبطة بها بشكل دائم. لا يمكن التراجع عن هذا الإجراء.",
        subscriptionWarning: "هام: إذا كان لديك اشتراك نشط، يرجى التأكد من إلغائه أولاً من إعدادات الاشتراك لتجنب الرسوم الإضافية.",
        enterPassword: "يرجى إدخال كلمة المرور لتأكيد الحذف:",
        // googleAuthNote: "بما أنك مسجل الدخول باستخدام Google، يمكنك حذف حسابك مباشرة دون تأكيد كلمة المرور.",
        passwordRequired: "كلمة المرور مطلوبة لحذف الحساب",
        accountDeleted: "تم حذف حسابك بنجاح",
        errorDeletingAccount: "خطأ في حذف الحساب. يرجى المحاولة مرة أخرى.",
        cancel: "إلغاء",
        confirm: "تأكيد الحذف"
    },
    de: {
        phone: "Telefon Nummer: ",
        loading: "Laden...",
        pleaseLogIn: "Bitte melden Sie sich an, um Ihr Konto anzuzeigen.",
        myAccount: "Mein Konto",
        accountInfo: "Kontoinformationen",
        name: "Name:",
        email: "E-Mail:",
        myBarberShops: "Meine Friseursalons",
        address: "Adresse:",
        services: "Dienstleistungen",
        availability: "Verfügbarkeit",
        shopImages: "Salonbilder",
        uniqueShopLink: "Eindeutiger Salon-Link",
        deleteShop: "Salon löschen",
        noShops: "Sie haben noch keine Friseursalons erstellt.",
        createShop: "Friseursalon erstellen",
        copied: "Kopiert!",
        copiedText: "Der Salon-Link wurde in Ihre Zwischenablage kopiert.",
        areYouSure: "Sind Sie sicher?",
        cantRevert: "Sie können dies nicht rückgängig machen!",
        yesDelete: "Ja, löschen!",
        success: "Erfolg",
        shopDeleted: "Ihr Friseursalon wurde gelöscht.",
        error: "Fehler",
        deleteAccount: "Konto löschen",
        deleteAccountWarning: "Sind Sie absolut sicher, dass Sie Ihr Konto löschen möchten?",
        deleteAccountDescription: "Dies wird Ihr Konto, alle Ihre Friseursalons und alle zugehörigen Daten dauerhaft löschen. Diese Aktion kann nicht rückgängig gemacht werden.",
        subscriptionWarning: "WICHTIG: Wenn Sie ein aktives Abonnement haben, stellen Sie bitte sicher, dass Sie es zuerst in Ihren Abonnement-Einstellungen kündigen, um weitere Gebühren zu vermeiden.",
        enterPassword: "Bitte geben Sie Ihr Passwort ein, um die Löschung zu bestätigen:",
        // googleAuthNote: "Da Sie mit Google angemeldet sind, können Sie Ihr Konto direkt ohne Passwortbestätigung löschen.",
        passwordRequired: "Passwort ist erforderlich, um das Konto zu löschen",
        accountDeleted: "Ihr Konto wurde erfolgreich gelöscht",
        errorDeletingAccount: "Fehler beim Löschen des Kontos. Bitte versuchen Sie es erneut.",
        cancel: "Abbrechen",
        confirm: "Löschen bestätigen"
    }
};

const AccountPage = () => {
    const {language} = useContext(LanguageContext);
    const t = translations[language];
    const [user, setUser] = useState(null);
    const [shops, setShops] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editMode, setEditMode] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const navigate = useNavigate();
    const {setUserShops, removeUserShop} = useStore();
    const [hasChanges, setHasChanges] = useState(false);
    const isGoogleUser = auth.currentUser?.providerData.some(p => p.providerId === 'google.com');
    const [newProfileImage, setNewProfileImage] = useState(null);
    const [userType, setUserType] = useState(null);
    const [editingShop, setEditingShop] = useState(null);
    const [editingAvailability, setEditingAvailability] = useState(null);

    const handleShopUpdate = (updatedShop) => {
        setShops(shops.map(shop =>
            shop.id === updatedShop.id ? updatedShop : shop
        ));
        setUserShops(prevShops =>
            prevShops.map(shop =>
                shop.id === updatedShop.id ? updatedShop : shop
            )
        );
    };

    const handleImageChange = (e) => {
        if (e.target.files[0]) {
            setNewProfileImage(e.target.files[0]);
            setHasChanges(true);
        }
    };

    const phoneInputStyle = {
        width: '100%',
        height: '2.5rem',
        fontSize: '1rem',
        borderRadius: '0.375rem',
        border: '1px solid rgb(209, 213, 219)',
        padding: '0.5rem 0.75rem',
        paddingLeft: '60px', // Make room for the flag
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
            if (currentUser) {
                // Get user data from Firestore
                const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
                const userData = userDoc.data();

                // Combine auth user with Firestore data
                setUser({
                    ...currentUser,
                    phoneNumber: userData?.phoneNumber || '',
                    userType: userData?.userType || ''  // Add userType here
                });
                setUserType(userData?.userType); // Set userType in state
                setName(currentUser.displayName || '');
                setEmail(currentUser.email || '');
                setPhone(userData?.phoneNumber || '');
                await fetchShopData(currentUser.uid);
            } else {
                navigate('/auth');
            }
            setLoading(false);
        });

        return () => unsubscribe();
    }, [navigate]);

    const handleDeleteAccount = async () => {
        const user = auth.currentUser;
        if (!user) {
            console.error('No user found');
            return;
        }

        try {
            // First step: Email confirmation
            const {value: confirmEmail} = await Swal.fire({
                title: t.confirmEmail,
                input: 'email',
                inputLabel: t.enterEmailToConfirm,
                inputPlaceholder: user.email,
                showCancelButton: true,
                confirmButtonColor: '#d81046',
                cancelButtonColor: '#168dd8',
                inputValidator: (value) => {
                    if (value !== user.email) {
                        return t.emailDoesNotMatch;
                    }
                }
            });

            if (!confirmEmail) return;

            // Second step: Password confirmation for non-Google users
            if (!isGoogleUser) {
                // Add reset password option
                const {value: action} = await Swal.fire({
                    title: t.deleteAccountWarning,
                    html: `
                    <p class="mb-4">${t.deleteAccountDescription}</p>
                    <p class="mb-4 text-red-500 font-bold">${t.subscriptionWarning}</p>
                    <input type="password" id="password" class="swal2-input" placeholder="${t.enterPassword}">
                    <p class="mt-4 text-sm text-gray-600">${t.resetPasswordDescription}</p>
                `,
                    showCancelButton: true,
                    showDenyButton: true,
                    confirmButtonColor: '#d81046',
                    denyButtonColor: '#4F46E5',
                    cancelButtonColor: '#168dd8',
                    confirmButtonText: t.confirm,
                    denyButtonText: t.resetPasswordFirst,
                    cancelButtonText: t.cancel,
                    preConfirm: async () => {
                        const password = document.getElementById('password')?.value;
                        if (!password) {
                            Swal.showValidationMessage(t.passwordRequired);
                            return false;
                        }
                        return {type: 'delete', password};
                    },
                    preDeny: () => {
                        return {type: 'reset'};
                    }
                });

                if (!action) return;

                if (action.type === 'reset') {
                    try {
                        await sendPasswordResetEmail(auth, user.email);
                        await Swal.fire({
                            title: t.success,
                            text: t.resetPasswordSent,
                            icon: 'success',
                            confirmButtonText: 'OK'
                        });
                        return;
                    } catch (error) {
                        console.error('Error sending reset email:', error);
                        throw error;
                    }
                }

                // Proceed with deletion if password was provided
                if (action.type === 'delete') {
                    try {
                        const credential = EmailAuthProvider.credential(user.email, action.password);
                        await reauthenticateWithCredential(user, credential);
                    } catch (error) {
                        throw new Error(t.authenticationFailed);
                    }
                }
            } else {
                // Additional confirmation step for Google users
                const {isConfirmed} = await Swal.fire({
                    title: t.deleteAccountWarning,
                    html: `
                    <p class="mb-4">${t.deleteAccountDescription}</p>
                    <p class="mb-4 text-red-500 font-bold">${t.subscriptionWarning}</p>
                `,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#d81046',
                    cancelButtonColor: '#168dd8',
                    confirmButtonText: t.confirm,
                    cancelButtonText: t.cancel,
                });

                if (!isConfirmed) return;
            }

            // Show loading state
            Swal.fire({
                title: t.processing,
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                }
            });

            // Delete all bookings associated with the user
            const bookingsRef = collection(db, 'bookings');
            const bookingsQuery = query(
                bookingsRef,
                where('userEmail', '==', user.email.toLowerCase())
            );
            const bookingsSnapshot = await getDocs(bookingsQuery);

            // Delete each booking
            const bookingDeletions = bookingsSnapshot.docs.map(doc =>
                deleteDoc(doc.ref)
            );
            await Promise.all(bookingDeletions);

            // Delete notifications associated with the user's email
            const notificationsRef = collection(db, 'notifications');
            const notificationsQuery = query(
                notificationsRef,
                where('userEmail', '==', user.email.toLowerCase())
            );
            const notificationsSnapshot = await getDocs(notificationsQuery);

            // Delete each notification
            const notificationDeletions = notificationsSnapshot.docs.map(doc =>
                deleteDoc(doc.ref)
            );
            await Promise.all(notificationDeletions);

            // Store user info before deletion
            const userInfo = {
                email: user.email,
                displayName: user.displayName,
                deletedAt: serverTimestamp(),
                uid: user.uid
            };

            // Create deletion record
            await setDoc(doc(db, 'deletedAccounts', user.uid), userInfo);

            // Clean up user data and shops
            if (shops && shops.length > 0) {
                for (const shop of shops) {
                    try {
                        await deleteDoc(doc(db, 'barberShops', shop.id));
                    } catch (error) {
                        console.error('Error deleting shop:', error);
                    }
                }
            }

            // Delete user document
            await deleteDoc(doc(db, 'users', user.uid));

            // Small delay to ensure cloud function is triggered
            await new Promise(resolve => setTimeout(resolve, 1000));

            // Delete auth account
            await deleteUser(user);

            // Show success message
            await Swal.fire({
                title: t.success,
                text: t.accountDeleted,
                icon: 'success',
                confirmButtonText: 'OK'
            });

            navigate('/');

        } catch (error) {
            console.error('Error deleting account:', error);
            Swal.fire({
                title: t.error,
                text: error.message || t.errorDeletingAccount,
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    };

    const formatAvailability = (availability) => {
        if (!availability || typeof availability !== 'object') {
            return 'No availability information';
        }

        const formattedAvailability = Object.entries(availability)
            .filter(([_, hours]) => hours !== null && typeof hours === 'object' && hours.open && hours.close)
            .map(([day, hours]) => `${day}: ${hours.open} - ${hours.close}`);

        return formattedAvailability.length > 0
            ? formattedAvailability.join(', ')
            : 'No available hours set';
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            Swal.fire({
                title: t.copied,
                text: t.copiedText,
                icon: 'success',
                timer: 2000,
                showConfirmButton: false
            });
        }, (err) => {
            console.error('Could not copy text: ', err);
        });
    };

    const deleteShop = async (shopId, imageUrls) => {
        try {
            await deleteDoc(doc(db, 'barberShops', shopId));
            const deletePromises = imageUrls.map(url => {
                const imageRef = ref(storage, url);
                return deleteObject(imageRef);
            });
            await Promise.all(deletePromises);
            setShops(shops.filter(shop => shop.id !== shopId));
            removeUserShop(shopId);
            Swal.fire({
                title: t.success,
                text: t.shopDeleted,
                icon: 'success',
                confirmButtonText: 'OK'
            });
        } catch (error) {
            console.error('Error deleting shop:', error);
            Swal.fire({
                title: t.error,
                text: t.errorDeleting,
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    };

    const handleDeleteShop = (shop) => {
        Swal.fire({
            title: t.areYouSure,
            text: t.cantRevert,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d81046',
            cancelButtonColor: '#168dd8',
            confirmButtonText: t.yesDelete
        }).then((result) => {
            if (result.isConfirmed) {
                deleteShop(shop.id, shop.imageUrls);
            }
        });
    };

    const fetchShopData = async (userId) => {
        try {
            const q = query(collection(db, 'barberShops'), where('ownerId', '==', userId));
            const querySnapshot = await getDocs(q);
            const shopList = [];
            for (const doc of querySnapshot.docs) {
                const shopData = doc.data();
                const imageUrls = await Promise.all(
                    shopData.imageUrls.map(async (imageRef) => {
                        try {
                            return await getDownloadURL(ref(storage, imageRef));
                        } catch (error) {
                            console.error('Error fetching image URL:', error);
                            return null;
                        }
                    })
                );
                shopList.push({id: doc.id, ...shopData, imageUrls: imageUrls.filter(url => url !== null)});
            }
            setShops(shopList);
            setUserShops(shopList);
        } catch (error) {
            console.error('Error fetching shop data:', error);
        }
    };

    const handleSaveProfile = async () => {
        try {
            let photoURL = user.photoURL; // Keep existing photo URL by default

            // Upload new profile image if provided
            if (newProfileImage) {
                const imageRef = ref(storage, `profile_images/${auth.currentUser.uid}`);
                await uploadBytes(imageRef, newProfileImage);
                photoURL = await getDownloadURL(imageRef);
            }

            // Update profile with new photo URL and display name
            await updateProfile(auth.currentUser, {
                displayName: name,
                photoURL: photoURL
            });

            // Check if email is different and user is not Google authenticated
            if (email !== auth.currentUser.email && !isGoogleUser) {
                await updateEmail(auth.currentUser, email);
            }

            const formattedPhone = phone ? `+${phone.replace(/\D/g, '')}` : null;

            // Update user in Firestore
            const userRef = doc(db, 'users', auth.currentUser.uid);
            await setDoc(userRef, {
                phoneNumber: formattedPhone,
                displayName: name,
                email: auth.currentUser.email,
                photoURL: photoURL, // Add photoURL to Firestore as well
                lastUpdated: serverTimestamp()
            }, {merge: true});

            setEditMode(false);
            setNewProfileImage(null); // Reset the image state
            setUser({
                ...auth.currentUser,
                email: email,
                phoneNumber: formattedPhone,
                photoURL: photoURL
            });

            Swal.fire({
                title: t.success,
                text: t.profileUpdated,
                icon: 'success',
                confirmButtonText: 'OK'
            });
        } catch (error) {
            console.error('Error updating profile:', error);
            let errorMessage = t.errorUpdatingProfile;

            if (isGoogleUser && email !== auth.currentUser.email) {
                errorMessage = "Cannot change email for Google-authenticated accounts";
            }

            Swal.fire({
                title: t.error,
                text: errorMessage,
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    };

    const handleEditShop = (shopId) => {
        console.log('Edit shop:', shopId);
        Swal.fire({
            title: t.comingSoon,
            text: t.shopEditingFunctionality,
            icon: 'info',
            confirmButtonText: 'OK'
        });
    };

    if (loading) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <div className="loading loading-spinner loading-lg text-primary"></div>
            </div>
        );
    }

    if (!user) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <div className="card bg-base-200 shadow-xl">
                    <div className="card-body">
                        <h2 className="card-title text-error">Access Denied</h2>
                        <p>{t.pleaseLogIn}</p>
                        <div className="card-actions justify-end">
                            <button className="btn btn-primary" onClick={() => navigate('/auth')}>
                                Login
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-base-100">
            {/* Modern Header */}
            <div className="navbar bg-base-200 shadow-lg px-4 sm:px-8">
                <div className="flex-1">
                    <h1 className="text-2xl font-bold">{t.myAccount}</h1>
                </div>
                <div className="flex-none gap-2">
                    <div className="dropdown dropdown-end">
                        <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
                            <div className="w-10 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                                <img
                                    src={user.photoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${user.displayName || user.email}`}
                                    alt="Profile"
                                />
                            </div>
                        </label>
                    </div>
                </div>
            </div>

            <div className="container mx-auto px-4 py-8 max-w-7xl">
                {/* Profile Section */}
                <div className="card bg-base-200 shadow-xl mb-8">
                    <div className="card-body">
                        <div className="flex flex-col md:flex-row gap-8">
                            {/* Profile Image Section */}
                            <div className="flex flex-col items-center space-y-4">
                                <div className="relative group">
                                    <div
                                        className="w-32 h-32 rounded-full overflow-hidden ring-4 ring-primary ring-offset-base-100 ring-offset-2">
                                        <img
                                            src={newProfileImage
                                                ? URL.createObjectURL(newProfileImage)
                                                : (user.photoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${user.displayName || user.email}`)}
                                            alt="Profile"
                                            className="w-full h-full object-cover"
                                        />
                                    </div>
                                    {editMode && (
                                        <label
                                            htmlFor="profile-image-upload"
                                            className="absolute inset-0 flex items-center justify-center bg-black/50
                                                     text-white opacity-0 group-hover:opacity-100 rounded-full cursor-pointer
                                                     transition-all duration-200"
                                        >
                                            <Camera className="w-8 h-8"/>
                                        </label>
                                    )}
                                    <input
                                        id="profile-image-upload"
                                        type="file"
                                        className="hidden"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                    />
                                </div>
                                {editMode && newProfileImage && (
                                    <button
                                        onClick={() => {
                                            setNewProfileImage(null);
                                            setHasChanges(name !== user.displayName ||
                                                (!isGoogleUser && email !== user.email) ||
                                                phone !== user.phoneNumber);
                                        }}
                                        className="btn btn-ghost btn-sm"
                                    >
                                        <X className="w-4 h-4 mr-2"/>
                                        Cancel Photo
                                    </button>
                                )}
                            </div>

                            {/* Profile Info Section */}
                            <div className="flex-1 space-y-6">
                                <div className="flex justify-between items-center">
                                    <h2 className="text-2xl font-bold">{t.accountInfo}</h2>
                                    {!editMode ? (
                                        <button
                                            onClick={() => setEditMode(true)}
                                            className="btn btn-primary btn-sm"
                                        >
                                            <Edit3 className="w-4 h-4 mr-2"/>
                                            Edit Profile
                                        </button>
                                    ) : (
                                        <div className="flex gap-2">
                                            <button
                                                onClick={handleSaveProfile}
                                                className="btn btn-primary btn-sm"
                                                disabled={!hasChanges}
                                            >
                                                <Save className="w-4 h-4 mr-2"/>
                                                Save
                                            </button>
                                            <button
                                                onClick={() => setEditMode(false)}
                                                className="btn btn-ghost btn-sm"
                                            >
                                                <X className="w-4 h-4 mr-2"/>
                                                Cancel
                                            </button>
                                        </div>
                                    )}
                                </div>

                                {editMode ? (
                                    <div className="grid gap-6">
                                        <div className="form-control">
                                            <label className="label">
                                                <span className="label-text">{t.name}</span>
                                            </label>
                                            <input
                                                type="text"
                                                value={name}
                                                onChange={(e) => {
                                                    setName(e.target.value);
                                                    setHasChanges(true);
                                                }}
                                                className="input input-bordered w-full"
                                            />
                                        </div>

                                        <div className="form-control">
                                            <label className="label">
                                                <span className="label-text">{t.email}</span>
                                            </label>
                                            {isGoogleUser ? (
                                                <div className="input input-bordered flex items-center opacity-50">
                                                    {email}
                                                </div>
                                            ) : (
                                                <input
                                                    type="email"
                                                    value={email}
                                                    onChange={(e) => {
                                                        setEmail(e.target.value);
                                                        setHasChanges(true);
                                                    }}
                                                    className="input input-bordered w-full"
                                                />
                                            )}
                                        </div>

                                        <div className="form-control">
                                            <label className="label">
                                                <span className="label-text">{t.phone}</span>
                                            </label>
                                            <PhoneInput
                                                country={'tr'}
                                                value={phone}
                                                onChange={(value) => {
                                                    setPhone(value);
                                                    setHasChanges(true);
                                                }}
                                                containerClass="phone-input"
                                                inputClass="input input-bordered w-full"
                                                buttonClass="phone-input-button"
                                                dropdownClass="phone-input-dropdown"
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="grid gap-4">
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                            <div className="stats bg-base-300 shadow">
                                                <div className="stat">
                                                    <div className="stat-title">{t.name}</div>
                                                    <div className="stat-value text-lg">
                                                        {user.displayName || 'Not set'}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="stats bg-base-300 shadow">
                                                <div className="stat">
                                                    <div className="stat-title">{t.email}</div>
                                                    <div className="stat-value text-lg">{user.email}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="stats bg-base-300 shadow">
                                            <div className="stat">
                                                <div className="stat-title">{t.phone}</div>
                                                <div className="stat-value text-lg">
                                                    {user.phoneNumber ? (
                                                        user.phoneNumber.startsWith('+')
                                                            ? user.phoneNumber
                                                            : `+${user.phoneNumber}`
                                                    ) : 'Not set'}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Shops Section */}
                {userType !== 'customer' && (
                    <div className="space-y-6">
                        <div className="flex justify-between items-center">
                            <h2 className="text-2xl font-bold">{t.myBarberShops}</h2>
                            <button
                                onClick={() => navigate('/create-shop')}
                                className="btn btn-primary"
                            >
                                <Plus className="w-4 h-4 mr-2"/>
                                {t.createShop}
                            </button>
                        </div>

                        {shops.length > 0 ? (
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                {shops.map((shop) => (
                                    <div key={shop.id} className="card bg-base-200 shadow-xl">
                                        <figure className="px-4 pt-4">
                                            <div className="carousel w-full rounded-xl">
                                                {shop.imageUrls.map((url, index) => (
                                                    <div key={index} className="carousel-item w-full">
                                                        <img
                                                            src={url}
                                                            alt={`${shop.name} - ${index + 1}`}
                                                            className="w-full h-48 object-cover rounded-xl"
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </figure>
                                        <div className="card-body">
                                            <h3 className="card-title">
                                                {shop.name}
                                                <div className="badge badge-primary">{shop.services.length} services
                                                </div>
                                            </h3>

                                            <div className="space-y-4">
                                                <p className="flex items-center gap-2">
                                                    <MapPin className="w-4 h-4"/>
                                                    {shop.address}
                                                </p>

                                                <div className="collapse collapse-plus bg-base-300">
                                                    <input type="checkbox"/>
                                                    <div className="collapse-title font-medium">
                                                        Services & Pricing
                                                    </div>
                                                    <div className="collapse-content">
                                                        <ul className="space-y-2">
                                                            {shop.services.map((service, index) => (
                                                                <li key={index} className="flex justify-between">
                                                                    <span>{service.name}</span>
                                                                    <span
                                                                        className="font-semibold">€{service.price}</span>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>

                                                {/*Collapsable for the shop date and time in the account page*/}
                                                <AvailabilityAccordion shop={shop}/>
                                                {/*Collapsable for the shop date and time in the account page*/}

                                                <div className="collapse collapse-plus bg-base-300">
                                                    <input type="checkbox"/>
                                                    <div className="collapse-title font-medium">
                                                        Description
                                                    </div>
                                                    <div className="collapse-content">
                                                        <div
                                                            className="prose max-w-none" // Add this class for better typography
                                                            dangerouslySetInnerHTML={{
                                                                __html: shop.biography || shop.description
                                                            }}
                                                            style={{
                                                                // Add these styles to match your theme
                                                                '& h1': {color: 'hsl(var(--p))', marginBottom: '1rem'},
                                                                '& ul': {listStyle: 'disc', paddingLeft: '1.5rem'},
                                                                '& li': {marginBottom: '0.5rem'},
                                                                '& p': {marginBottom: '1rem'},
                                                                lineHeight: 1.6,
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-control">
                                                    <label className="label cursor-pointer">
                                                    <span className="label-text flex items-center gap-2">
                                                        <LinkIcon className="w-4 h-4"/>
                                                        Shop Link
                                                    </span>
                                                        <CopyLinkButton
                                                            linkToCopy={`${window.location.origin}/shop/${shop.uniqueUrl}`}
                                                        />
                                                    </label>
                                                    <div className="text-lg text-base-content/100 underline break-all">
                                                        {`${window.location.origin}/shop/${shop.uniqueUrl}`}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card-actions justify-end mt-4">
                                                <button
                                                    onClick={() => setEditingAvailability(shop)}
                                                    className="btn btn-secondary btn-sm"
                                                >
                                                    <Clock className="w-4 h-4 mr-2"/>
                                                    Manage Hours
                                                </button>

                                                <button
                                                    onClick={() => setEditingShop(shop)}
                                                    className="btn btn-primary btn-sm"
                                                >
                                                    <Edit2 className="w-4 h-4 mr-2"/>
                                                    Edit Shop
                                                </button>
                                                <button
                                                    onClick={() => handleDeleteShop(shop)}
                                                    className="btn btn-error btn-sm"
                                                >
                                                    <Trash2 className="w-4 h-4 mr-2"/>
                                                    {t.deleteShop}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="card bg-base-200 shadow-xl">
                                <div className="card-body text-center">
                                    <h3 className="text-xl font-bold mb-4">{t.noShops}</h3>
                                    <p className="text-base-content/70 mb-6">
                                        Create your first barber shop to start managing your business.
                                    </p>
                                    <button
                                        onClick={() => navigate('/create-shop')}
                                        className="btn btn-primary btn-wide mx-auto"
                                    >
                                        <Plus className="w-4 h-4 mr-2"/>
                                        {t.createShop}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                )}

                {/* Delete Account Section */}
                <div className="mt-16 card bg-base-200 shadow-xl">
                    <div className="card-body">
                        <h3 className="card-title text-error">Danger Zone</h3>
                        <p className="text-base-content/70">
                            Once you delete your account, there is no going back. Please be certain.
                        </p>
                        <div className="card-actions justify-end">
                            <button
                                onClick={handleDeleteAccount}
                                className="btn btn-error"
                            >
                                <Trash2 className="w-4 h-4 mr-2"/>
                                {t.deleteAccount}
                            </button>
                        </div>
                    </div>
                </div>

                {/* Bottom Spacing */}
                <div className="h-24"></div>
            </div>

            {/* Custom Styles */}
            <style jsx>{`
                .phone-input {
                    width: 100%;
                }

                .phone-input input {
                    width: 100% !important;
                    height: 3rem !important;
                    padding-left: 3.5rem !important;
                    background-color: transparent !important;
                }

                .phone-input-button {
                    border: 1px solid hsl(var(--bc) / 0.2) !important;
                    border-right: none !important;
                    background-color: transparent !important;
                }

                .phone-input-dropdown {
                    background-color: hsl(var(--b1)) !important;
                    color: hsl(var(--bc)) !important;
                }

                .phone-input-dropdown:hover {
                    background-color: hsl(var(--b2)) !important;
                }
            `}</style>

            {/* Bottom Spacing */}
            <div className="h-24"></div>

            {/* Add the modal here, right before the style tag */}
            {editingShop && (
                <EditBarberShopModal
                    shop={editingShop}
                    isOpen={true}
                    onClose={() => setEditingShop(null)}
                    onSave={handleShopUpdate}
                />
            )}

            {/* Custom Styles */}
            <style jsx>{`
                .phone-input {
                    width: 100%;
                }

                // ... rest of your styles
            `}</style>

            {editingAvailability && (
                <EditAvailabilityModal
                    shop={editingAvailability}
                    isOpen={true}
                    onClose={() => setEditingAvailability(null)}
                    onSave={handleShopUpdate}
                />
            )}

        </div>
    );
};

export default AccountPage;