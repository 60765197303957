import React, { useContext, useState } from 'react';
import { motion } from 'framer-motion';
import { Scissors, Clock, Sparkles, Search } from 'lucide-react';
import LanguageContext from "./LanguageContext";

// Service categories with icons
const serviceCategories = {
    haircuts: [0, 1, 4], // indices of haircut services
    facial: [2, 3],      // indices of facial services
    styling: [6, 7, 8],  // indices of styling services
    treatments: [5, 9]   // indices of treatment services
};

const presetServices = [
    { name: 'Herrenhaarschnitt', price: '25', icon: <Scissors className="w-5 h-5" />, duration: '30' },
    { name: 'Maschinenhaarschnitt', price: '20', icon: <Scissors className="w-5 h-5" />, duration: '20' },
    { name: 'Bartpflege', price: '15', icon: <Scissors className="w-5 h-5" />, duration: '15' },
    { name: 'Rasur', price: '20', icon: <Scissors className="w-5 h-5" />, duration: '20' },
    { name: 'Kinderhaarschnitt', price: '18', icon: <Scissors className="w-5 h-5" />, duration: '25' },
    { name: 'Färben', price: '45', icon: <Sparkles className="w-5 h-5" />, duration: '60' },
    { name: 'Strähnchen', price: '55', icon: <Sparkles className="w-5 h-5" />, duration: '90' },
    { name: 'Waschen & Föhnen', price: '15', icon: <Sparkles className="w-5 h-5" />, duration: '20' },
    { name: 'Styling', price: '10', icon: <Sparkles className="w-5 h-5" />, duration: '15' },
    { name: 'Kopfmassage', price: '12', icon: <Sparkles className="w-5 h-5" />, duration: '15' },
];

const ServiceCard = ({ service, index, t, onClick, isSelected }) => {  // Add isSelected prop
    return (
        <motion.button
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: index * 0.05 }}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={onClick}
            className={`relative group rounded-xl p-4 transition-all duration-200
                     border shadow-sm hover:shadow-md
                     flex flex-col gap-2 w-full
                     ${isSelected
                ? 'bg-primary/10 border-primary'
                : 'bg-base-100 hover:bg-primary/5 border-base-200 hover:border-primary/20'
            }`}
        >
            <div className="flex justify-between items-start">
                <div className="flex flex-col gap-1">
                    <span className="font-semibold text-left">
                        {t[Object.keys(t)[index]] || service.name}
                    </span>
                    <div className="flex items-center gap-2 text-sm opacity-70">
                        <Clock className="w-4 h-4" />
                        <span>{service.duration} min</span>
                    </div>
                </div>
                <div className="flex items-center justify-center w-8 h-8 rounded-lg bg-primary/10 text-primary">
                    {service.icon}
                </div>
            </div>

            <div className="flex justify-between items-center mt-2">
                <span className="text-lg font-bold text-primary">€{service.price}</span>
                <motion.div
                    initial={false}
                    className={`px-3 py-1 rounded-full text-xs 
                        ${isSelected
                        ? 'bg-primary text-primary-content'
                        : 'bg-primary/10 text-primary'
                    }`}
                >
                    {isSelected ? 'Selected' : 'Select'}
                </motion.div>
            </div>
        </motion.button>
    );
};

const PresetServiceSelector = ({ onServiceSelect, selectedServices }) => {  // Add selectedServices prop
    const { language } = useContext(LanguageContext);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("all");

    // Helper function to check if a service is selected
    const isServiceSelected = (service) => {
        return selectedServices?.some(s =>
            s.name === service.name && s.price === service.price
        ) ?? false;
    };

    const translations = {
        en: {
            menHaircut: "Men's Haircut",
            machineHaircut: "Machine Haircut",
            beardGrooming: "Beard Grooming",
            shave: "Shave",
            kidsHaircut: "Kids Haircut",
            coloring: "Coloring",
            highlights: "Highlights",
            washAndBlow: "Wash & Blow Dry",
            styling: "Styling",
            headMassage: "Head Massage",
            searchPlaceholder: "Search services...",
            categories: {
                all: "All Services",
                haircuts: "Haircuts",
                facial: "Facial Care",
                styling: "Styling",
                treatments: "Treatments"
            }
        },
        tr: {
            menHaircut: "Erkek Saç Kesimi",
            machineHaircut: "Makine ile Saç Kesimi",
            beardGrooming: "Sakal Bakımı",
            shave: "Tıraş",
            kidsHaircut: "Çocuk Saç Kesimi",
            coloring: "Boyama",
            highlights: "Röfle",
            washAndBlow: "Yıkama ve Fön",
            styling: "Şekillendirme",
            headMassage: "Kafa Masajı",
            searchPlaceholder: "Hizmet ara...",
            categories: {
                all: "Tüm Hizmetler",
                haircuts: "Saç Kesimi",
                facial: "Yüz Bakımı",
                styling: "Şekillendirme",
                treatments: "Bakımlar"
            }
        },
        ar: {
            menHaircut: "قص شعر رجالي",
            machineHaircut: "قص الشعر بالماكينة",
            beardGrooming: "تهذيب اللحية",
            shave: "حلاقة",
            kidsHaircut: "قص شعر الأطفال",
            coloring: "صبغ الشعر",
            highlights: "هايلايت",
            washAndBlow: "غسيل وتجفيف",
            styling: "تصفيف",
            headMassage: "تدليك الرأس",
            searchPlaceholder: "البحث عن الخدمات...",
            categories: {
                all: "جميع الخدمات",
                haircuts: "قص الشعر",
                facial: "العناية بالوجه",
                styling: "التصفيف",
                treatments: "العلاجات"
            }
        },
        de: {
            menHaircut: "Herrenhaarschnitt",
            machineHaircut: "Maschinenhaarschnitt",
            beardGrooming: "Bartpflege",
            shave: "Rasur",
            kidsHaircut: "Kinderhaarschnitt",
            coloring: "Färben",
            highlights: "Strähnchen",
            washAndBlow: "Waschen & Föhnen",
            styling: "Styling",
            headMassage: "Kopfmassage",
            searchPlaceholder: "Dienste suchen...",
            categories: {
                all: "Alle Dienste",
                haircuts: "Haarschnitte",
                facial: "Gesichtspflege",
                styling: "Styling",
                treatments: "Behandlungen"
            }
        }
    };

    const t = translations[language];

    const filteredServices = presetServices.filter((service, index) => {
        const serviceName = t[Object.keys(t)[index]] || service.name;
        const matchesSearch = serviceName.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesCategory = selectedCategory === "all" ||
            (serviceCategories[selectedCategory] || []).includes(index);
        return matchesSearch && matchesCategory;
    });

    return (
        <div className="space-y-6">
            {/* Search and Categories */}
            <div className="space-y-4">
                <div className="relative">
                    <Search
                        className="absolute left-3 top-1/2 transform -translate-y-1/2 text-base-content/50 w-5 h-5"/>
                    <input
                        type="text"
                        placeholder={t.searchPlaceholder}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="w-full pl-10 pr-4 py-2 rounded-xl border border-base-200 bg-base-100
                                 focus:outline-none focus:ring-2 focus:ring-primary/20 transition-all"
                    />
                </div>

                <div className="flex gap-2 overflow-x-auto pb-2 scrollbar-hide">
                    {Object.entries(t.categories).map(([key, value]) => (
                        <button
                            key={key}
                            onClick={() => setSelectedCategory(key)}
                            className={`px-4 py-2 rounded-full whitespace-nowrap transition-all
                                ${selectedCategory === key
                                ? 'bg-primary text-primary-content'
                                : 'bg-base-200 hover:bg-base-300'}`}
                        >
                            {value}
                        </button>
                    ))}
                </div>
            </div>

            {/* Services Grid */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                {filteredServices.map((service, index) => (
                    <ServiceCard
                        key={index}
                        service={service}
                        index={index}
                        t={t}
                        isSelected={isServiceSelected(service)}
                        onClick={(e) => {
                            e.preventDefault();
                            onServiceSelect(service);
                        }}
                    />
                ))}
            </div>
        </div>
    );
};

export default PresetServiceSelector;