import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
    Download, Mail, X, Copy,
    CheckCircle, Share2, FileText,
    Printer
} from 'lucide-react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const InvoiceDialog = ({
                           isOpen,
                           onClose,
                           booking,
                           shopDetails
                       }) => {
    const [copied, setCopied] = useState(false);

    if (!isOpen || !booking || !shopDetails) return null;

    const invoiceNumber = `INV-${booking.id?.slice(0, 8).toUpperCase()}`;
    const invoiceDate = new Date().toLocaleDateString('en-GB');
    const dueDate = new Date(booking.selectedDate).toLocaleDateString('en-GB');

    const totalAmount = booking.selectedServices?.reduce(
        (sum, service) => sum + parseFloat(service.price),
        0
    ) || 0;

    const generatePDF = () => {
        // Create new PDF document
        const doc = new jsPDF();

        // Add shop logo/header
        doc.setFontSize(20);
        doc.setTextColor(44, 62, 80);
        doc.text(shopDetails.name, 20, 20);

        // Add invoice details
        doc.setFontSize(10);
        doc.setTextColor(52, 73, 94);
        doc.text([
            `Invoice #: ${invoiceNumber}`,
            `Date: ${invoiceDate}`,
            `Due Date: ${dueDate}`,
        ], 20, 35);

        // Add shop details
        doc.text([
            shopDetails.address,
            `Phone: ${shopDetails.phoneNumber}`,
            `Email: ${shopDetails.email}`,
        ], 20, 55);

        // Add customer details
        doc.setFontSize(12);
        doc.text('Bill To:', 20, 75);
        doc.setFontSize(10);
        doc.text([
            booking.userName,
            booking.userEmail,
            booking.userPhone || 'No phone provided',
        ], 20, 82);

        // Add services table
        const tableData = booking.selectedServices?.map(service => [
            service.name,
            `${service.duration || '-'} min`,
            `€${parseFloat(service.price).toFixed(2)}`,
        ]) || [];

        doc.autoTable({
            startY: 100,
            head: [['Service', 'Duration', 'Price']],
            body: tableData,
            theme: 'grid',
            headStyles: {
                fillColor: [52, 73, 94],
                textColor: [255, 255, 255],
                fontSize: 10,
            },
            styles: {
                fontSize: 9,
                cellPadding: 5,
            },
        });

        // Add total amount
        const finalY = doc.previousAutoTable.finalY || 120;
        doc.setFontSize(12);
        doc.setFont('helvetica', 'bold');
        doc.text(`Total Amount: €${totalAmount.toFixed(2)}`, 150, finalY + 20);

        // Add footer
        doc.setFontSize(8);
        doc.setFont('helvetica', 'normal');
        doc.setTextColor(128, 128, 128);
        doc.text([
            'Thank you for your business!',
            `${shopDetails.name} - ${shopDetails.address}`,
            `Generated on ${new Date().toLocaleDateString('en-GB')} at ${new Date().toLocaleTimeString('en-GB')}`,
        ], 20, 270, {
            align: 'left',
        });

        // Add appointment details
        doc.setFontSize(9);
        doc.setTextColor(52, 73, 94);
        doc.text([
            'Appointment Details:',
            `Date: ${new Date(booking.selectedDate).toLocaleDateString('en-GB')}`,
            `Time: ${booking.selectedTime}`,
            `Status: ${booking.status}`,
        ], 20, finalY + 40);

        // Save the PDF
        doc.save(`Invoice-${invoiceNumber}.pdf`);
    };

    const handleCopyToClipboard = () => {
        const invoiceText = `
Invoice #${invoiceNumber}
${shopDetails.name}
${shopDetails.address}

Bill To:
${booking.userName}
${booking.userEmail}
${booking.userPhone || 'No phone provided'}

Date: ${invoiceDate}
Due Date: ${dueDate}

Services:
${booking.selectedServices?.map(service =>
            `${service.name}: €${service.price}`
        ).join('\n')}

Total Amount: €${totalAmount.toFixed(2)}
    `;

        navigator.clipboard.writeText(invoiceText);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    const handlePrint = () => {
        window.print();
    };

    const handleEmailShare = () => {
        const subject = `Invoice ${invoiceNumber} from ${shopDetails.name}`;
        const body = `Please find your invoice attached for services on ${dueDate}.`;
        window.location.href = `mailto:${booking.userEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    };

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="fixed inset-0 z-50 flex items-center justify-center bg-black/50"
                >
                    <motion.div
                        initial={{ scale: 0.9, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0.9, opacity: 0 }}
                        className="bg-base-100 rounded-lg shadow-xl max-w-3xl w-full mx-4 p-6"
                    >
                        {/* Invoice Header */}
                        <div className="flex justify-between items-start mb-8">
                            <div>
                                <h2 className="text-2xl font-bold">Invoice</h2>
                                <p className="text-base-content/60">{invoiceNumber}</p>
                            </div>
                            <button
                                onClick={onClose}
                                className="btn btn-ghost btn-sm"
                            >
                                <X className="w-5 h-5" />
                            </button>
                        </div>

                        {/* Invoice Content */}
                        <div className="space-y-8 mb-8">
                            {/* Business & Client Info */}
                            <div className="grid md:grid-cols-2 gap-8">
                                <div>
                                    <h3 className="font-semibold mb-2">From:</h3>
                                    <div className="text-base-content/80">
                                        <p className="font-medium">{shopDetails.name}</p>
                                        <p>{shopDetails.address}</p>
                                        <p>{shopDetails.email}</p>
                                        <p>{shopDetails.phoneNumber}</p>
                                    </div>
                                </div>
                                <div>
                                    <h3 className="font-semibold mb-2">Bill To:</h3>
                                    <div className="text-base-content/80">
                                        <p className="font-medium">{booking.userName}</p>
                                        <p>{booking.userEmail}</p>
                                        <p>{booking.userPhone || 'No phone provided'}</p>
                                    </div>
                                </div>
                            </div>

                            {/* Dates */}
                            <div className="grid md:grid-cols-2 gap-8">
                                <div>
                                    <h3 className="font-semibold mb-2">Invoice Date:</h3>
                                    <p className="text-base-content/80">{invoiceDate}</p>
                                </div>
                                <div>
                                    <h3 className="font-semibold mb-2">Appointment Date:</h3>
                                    <p className="text-base-content/80">{dueDate} at {booking.selectedTime}</p>
                                </div>
                            </div>

                            {/* Services Table */}
                            <div>
                                <h3 className="font-semibold mb-4">Services:</h3>
                                <div className="overflow-x-auto">
                                    <table className="table table-zebra w-full">
                                        <thead>
                                        <tr>
                                            <th>Service</th>
                                            <th className="text-right">Price</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {booking.selectedServices?.map((service, index) => (
                                            <tr key={index}>
                                                <td>{service.name}</td>
                                                <td className="text-right">€{service.price}</td>
                                            </tr>
                                        ))}
                                        <tr className="font-bold">
                                            <td>Total</td>
                                            <td className="text-right">€{totalAmount.toFixed(2)}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        {/* Action Buttons */}
                        <div className="flex flex-wrap gap-2 justify-end">
                            <button
                                onClick={handleCopyToClipboard}
                                className="btn btn-ghost gap-2"
                            >
                                {copied ? (
                                    <CheckCircle className="w-4 h-4 text-success" />
                                ) : (
                                    <Copy className="w-4 h-4" />
                                )}
                                Copy Text
                            </button>
                            <button
                                onClick={handlePrint}
                                className="btn btn-ghost gap-2"
                            >
                                <Printer className="w-4 h-4" />
                                Print
                            </button>
                            <button
                                onClick={handleEmailShare}
                                className="btn btn-ghost gap-2"
                            >
                                <Mail className="w-4 h-4" />
                                Email
                            </button>
                            <button
                                onClick={generatePDF}
                                className="btn btn-primary gap-2"
                            >
                                <Download className="w-4 h-4" />
                                Download PDF
                            </button>
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default InvoiceDialog;