import React from 'react';
import {AreaChart} from "@tremor/react";
import {CheckCircle, DollarSign, TrendingDown, TrendingUp, Users, XCircle} from 'lucide-react';
import {Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip} from 'recharts';
import * as bookings from "framer-motion/m";
import MinimalTrendsChart from "./BookingTrendsChart";

const valueFormatter = (number) =>
    new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }).format(number);

const percentageFormatter = (number) =>
    new Intl.NumberFormat("en-US", {
        style: "percent",
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
    }).format(number / 100);

// Custom tooltip component for charts
const CustomTooltip = ({active, payload, label}) => {
    if (!active || !payload) return null;

    return (
        <div className="bg-base-200 border border-base-300 rounded-lg shadow-xl p-3">
            <p className="text-base-content font-medium mb-2">{label}</p>
            {payload.map((entry, index) => (
                <div key={index} className="flex items-center gap-2 text-sm">
                    <div
                        className="w-3 h-3 rounded-full"
                        style={{backgroundColor: entry.color}}
                    />
                    <span className="text-base-content/80">{entry.name}:</span>
                    <span className="text-base-content font-medium">
                        {entry.name === 'revenue' ? '€' : ''}{entry.value}
                    </span>
                </div>
            ))}
        </div>
    );
};

const CustomAreaChart = ({ data }) => (
    <AreaChart
        data={data}
        index="name"
        categories={["completed", "cancelled", "pending", "revenue"]} // Changed categories
        colors={["emerald", "rose", "amber", "cyan"]} // Changed colors
        valueFormatter={(value, category) =>
            category === "revenue" ? `€${value}` : value.toFixed(0)
        }
        showLegend={true}
        yAxisWidth={60}
        showAnimation={true}
        className="h-[300px] text-base-content"
        customTooltip={CustomTooltip}
        curveType="monotone"
        showXAxis={true}
        showYAxis={true}
        showGridLines={true}
        autoMinValue={true}
        startEndOnly={data.length > 10}
        style={{
            '--tremor-background-color': 'transparent',
            '--tremor-border-color': 'hsl(var(--bc) / 0.2)',
            '--tremor-brand-color': 'hsl(var(--p))',
            '--tremor-ring-color': 'hsl(var(--p))',
            '--tremor-track-color': 'transparent',
            '--tremor-text-color': 'hsl(var(--bc))',
        }}
    />
);

const CustomPieChart = ({data}) => {
    const COLORS = ['hsl(var(--su))', 'hsl(var(--er))', 'hsl(var(--wa))'];

    const CustomTooltip = ({active, payload}) => {
        if (!active || !payload || !payload.length) return null;

        return (
            <div className="bg-base-200 border border-base-300 shadow-xl rounded-lg p-3">
                <p className="text-base-content font-medium">{payload[0].name}</p>
                <p className="text-base-content/80 text-sm">
                    {payload[0].value} bookings ({Math.round(payload[0].percent * 100)}%)
                </p>
            </div>
        );
    };

    const renderCustomizedLabel = ({
                                       cx,
                                       cy,
                                       midAngle,
                                       innerRadius,
                                       outerRadius,
                                       percent,
                                       value
                                   }) => {
        const RADIAN = Math.PI / 180;
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text
                x={x}
                y={y}
                fill="hsl(var(--bc))"
                textAnchor={x > cx ? 'start' : 'end'}
                dominantBaseline="central"
                className="text-sm font-medium"
            >
                {value}
            </text>
        );
    };

    return (
        <div className="w-full h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                    <Pie
                        data={data}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={renderCustomizedLabel}
                        outerRadius={100}
                        fill="#8884d8"
                        dataKey="value"
                        nameKey="name"
                        className="stroke-base-100"
                        strokeWidth={2}
                    >
                        {data.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index]}
                                className="outline-none"
                            />
                        ))}
                    </Pie>
                    <Tooltip content={<CustomTooltip/>}/>
                    <Legend
                        formatter={(value, entry) => (
                            <span className="text-base-content/80">{value}</span>
                        )}
                        className="text-base-content"
                    />
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
};


const AnalyticsDashboard = ({stats, chartData, selectedDateRange, onRangeChange}) => {  // Add onRangeChange here
    console.log('ANALYTICS DASHBOARD RECEIVED:', {
        stats,
        chartData,
        selectedDateRange,
        onRangeChange
    });
    const bookingDistribution = [
        {
            name: 'Completed',
            value: stats?.completedBookings || 0,
        },
        {
            name: 'Cancelled',
            value: stats?.cancelledBookings || 0,
        },
        {
            name: 'Pending',
            value: (stats?.totalBookings || 0) - ((stats?.completedBookings || 0) + (stats?.cancelledBookings || 0)),
        }
    ];


    return (
        <div className="w-full space-y-8">
            {/* Stats Overview */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                {/* Total Bookings Card */}
                <div className="card bg-base-200 hover:bg-base-300 transition-colors">
                    <div className="card-body">
                        <div className="flex items-center justify-between">
                            <div className="w-12 h-12 rounded-2xl bg-primary/20 flex items-center justify-center">
                                <Users className="w-6 h-6 text-primary"/>
                            </div>
                            <div className="bg-base-300 px-2 py-1 rounded-lg flex items-center gap-1">
                                {stats?.bookingsGrowth >= 0 ? (
                                    <TrendingUp className="w-4 h-4 text-success"/>
                                ) : (
                                    <TrendingDown className="w-4 h-4 text-error"/>
                                )}
                                <span className={`text-sm font-semibold ${
                                    stats?.bookingsGrowth >= 0 ? 'text-success' : 'text-error'
                                }`}>
                                    {Math.abs(stats?.bookingsGrowth || 0)}%
                                </span>
                            </div>
                        </div>
                        <div className="mt-4">
                            <h2 className="text-3xl font-bold text-base-content">{stats?.totalBookings || 0}</h2>
                            <p className="text-base-content/60 text-sm mt-1">Total Appointments</p>
                        </div>
                    </div>
                </div>

                {/* Completed Bookings */}
                <div className="card bg-base-200 hover:bg-base-300 transition-colors">
                    <div className="card-body">
                        <div className="flex items-center justify-between">
                            <div className="w-12 h-12 rounded-2xl bg-success/20 flex items-center justify-center">
                                <CheckCircle className="w-6 h-6 text-success"/>
                            </div>
                            <div className="bg-base-300 px-2 py-1 rounded-lg flex items-center gap-1">
                                {stats?.completedGrowth >= 0 ? (
                                    <TrendingUp className="w-4 h-4 text-success"/>
                                ) : (
                                    <TrendingDown className="w-4 h-4 text-error"/>
                                )}
                                <span className={`text-sm font-semibold ${
                                    stats?.completedGrowth >= 0 ? 'text-success' : 'text-error'
                                }`}>
                    {Math.abs(stats?.completedGrowth || 0)}%
                </span>
                            </div>
                        </div>
                        <div className="mt-4">
                            <h2 className="text-3xl font-bold text-base-content">{stats?.completedBookings || 0}</h2>
                            <p className="text-base-content/60 text-sm mt-1">Completed Sessions</p>
                        </div>
                    </div>
                </div>

                {/* Cancelled Bookings */}
                <div className="card bg-base-200 hover:bg-base-300 transition-colors">
                    <div className="card-body">
                        <div className="flex items-center justify-between">
                            <div className="w-12 h-12 rounded-2xl bg-error/20 flex items-center justify-center">
                                <XCircle className="w-6 h-6 text-error"/>
                            </div>
                            <div className="bg-base-300 px-2 py-1 rounded-lg flex items-center gap-1">
                                {stats?.cancelledGrowth >= 0 ? (
                                    <TrendingUp className="w-4 h-4 text-success"/>
                                ) : (
                                    <TrendingDown className="w-4 h-4 text-error"/>
                                )}
                                <span className={`text-sm font-semibold ${
                                    stats?.cancelledGrowth >= 0 ? 'text-success' : 'text-error'
                                }`}>
                    {Math.abs(stats?.cancelledGrowth || 0)}%
                </span>
                            </div>
                        </div>
                        <div className="mt-4">
                            <h2 className="text-3xl font-bold text-base-content">{stats?.cancelledBookings || 0}</h2>
                            <p className="text-base-content/60 text-sm mt-1">Cancelled Sessions</p>
                        </div>
                    </div>
                </div>

                {/* Revenue Card */}
                <div className="card bg-base-200 hover:bg-base-300 transition-colors">
                    <div className="card-body">
                        <div className="flex items-center justify-between">
                            <div className="w-12 h-12 rounded-2xl bg-secondary/20 flex items-center justify-center">
                                <DollarSign className="w-6 h-6 text-secondary"/>
                            </div>
                            <div className="bg-base-300 px-2 py-1 rounded-lg flex items-center gap-1">
                                {stats?.revenueGrowth >= 0 ? (
                                    <TrendingUp className="w-4 h-4 text-success"/>
                                ) : (
                                    <TrendingDown className="w-4 h-4 text-error"/>
                                )}
                                <span className={`text-sm font-semibold ${
                                    stats?.revenueGrowth >= 0 ? 'text-success' : 'text-error'
                                }`}>
                    {Math.abs(stats?.revenueGrowth || 0)}%
                </span>
                            </div>
                        </div>
                        <div className="mt-4">
                            <h2 className="text-3xl font-bold text-base-content">
                                €{stats?.revenue?.toFixed(2) || '0.00'}
                            </h2>
                            <p className="text-base-content/60 text-sm mt-1">Total Revenue</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Charts Section */}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                {/* Trend Analysis */}
                <MinimalTrendsChart
                    data={chartData}
                    selectedDateRange={selectedDateRange}
                    onRangeChange={onRangeChange}
                />

                {/* Distribution Analysis */}
                <div className="card bg-base-200 hover:bg-base-300 transition-colors">
                    <div className="card-body">
                        <div className="space-y-3">
                            <h3 className="text-lg font-semibold text-base-content">Booking Distribution</h3>
                            <div className="flex gap-3">
                                <div className="flex items-center gap-2">
                                    <div className="w-3 h-3 rounded-full bg-success"></div>
                                    <span className="text-sm text-base-content/70">Completed</span>
                                </div>
                                <div className="flex items-center gap-2">
                                    <div className="w-3 h-3 rounded-full bg-error"></div>
                                    <span className="text-sm text-base-content/70">Cancelled</span>
                                </div>
                                <div className="flex items-center gap-2">
                                    <div className="w-3 h-3 rounded-full bg-warning"></div>
                                    <span className="text-sm text-base-content/70">Pending</span>
                                </div>
                            </div>
                            <CustomPieChart data={bookingDistribution}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AnalyticsDashboard;