import React, {useContext, useEffect, useRef, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import useStore from '../store';
import {auth, db} from '../firebase';
import {onAuthStateChanged, signOut} from 'firebase/auth';
import LanguageContext from './LanguageContext';
import {
    Calendar,
    ChevronDown,
    Globe,
    LogOut,
    Menu,
    Monitor,
    Moon,
    Plus,
    Search,
    Sun,
    User,
    Users,
    X
} from 'lucide-react';
import {doc, getDoc} from 'firebase/firestore';
import NotificationButton from "./NotificationButton";
import MobielNotificaiotn from "./MobielNotificaiotn";
import NavbarChatButton from "./ShopOwnerChatButton";
import MobileChatButton from "./MobileShopOwnerButton";

const ShopOwnerButtons = ({user, userType, t}) => {
    if (!user || !userType || userType === 'customer' || userType === '') return null;

    return (
        <>
            <Link
                to="/dashboard/clients"
                className="btn btn-ghost btn-sm rounded-full hover:bg-primary/10 hover:text-primary transition-colors duration-200"
            >
                <Users className="w-4 h-4 mr-2"/>
                {t.clientManagement}
            </Link>
            <Link to="/create-shop" className="btn btn-primary btn-sm rounded-full">
                <Plus className="w-4 h-4 mr-2"/>
                {t.createBarberShop}
            </Link>
        </>
    );
};

// For customers only (appointments)
const CustomerButtons = ({user, userType, t}) => {
    if (!user || !userType || userType !== 'customer') return null;

    return (
        <Link to="/dashboard/customers" className="btn btn-ghost btn-sm rounded-full">
            <Calendar className="w-4 h-4 mr-2"/>
            {t.appointments}
        </Link>
    );
};

// Mobile versions
const MobileShopOwnerButtons = ({user, userType, t}) => {
    if (!user || !userType || userType === 'customer' || userType === '') return null;

    return (
        <>
            <Link
                to="/dashboard/clients"
                className="flex items-center p-3 rounded-lg hover:bg-primary/10 hover:text-primary transition-all duration-200"
            >
                <Users className="w-4 h-4 mr-3"/>
                {t.clientManagement}
            </Link>
            <Link
                to="/create-shop"
                className="flex items-center p-3 rounded-lg hover:bg-base-200"
            >
                <Plus className="w-4 h-4 mr-3"/>
                {t.createBarberShop}
            </Link>
        </>
    );
};

const MobileCustomerButtons = ({user, userType, t}) => {
    if (!user || !userType || userType !== 'customer') return null;

    return (
        <Link
            to="/dashboard/customers"
            className="flex items-center p-3 rounded-lg hover:bg-base-200"
        >
            <Calendar className="w-4 h-4 mr-3"/>
            {t.appointments}
        </Link>
    );
};

export const NavbarSpacer = () => {
    const [height, setHeight] = useState(64); // Default height

    useEffect(() => {
        const updateHeight = () => {
            const navbar = document.getElementById('main-navbar');
            if (navbar) {
                setHeight(navbar.offsetHeight);
            }
        };

        // Update on mount
        updateHeight();

        // Update on resize
        window.addEventListener('resize', updateHeight);

        // Update after a short delay to ensure all content is loaded
        const timeout = setTimeout(updateHeight, 100);

        return () => {
            window.removeEventListener('resize', updateHeight);
            clearTimeout(timeout);
        };
    }, []);

    return <div style={{height: `${height}px`}}/>;
};

const Navbar = () => {
    const {theme, toggleTheme} = useStore();
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const {language, changeLanguage} = useContext(LanguageContext);
    const [isOpen, setIsOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [userType, setUserType] = useState(null);
    const [isLoadingUserType, setIsLoadingUserType] = useState(true);
    const [isVisible, setIsVisible] = useState(true);
    const [isMobile, setIsMobile] = useState(false);
    const lastScrollY = useRef(0);
    console.log('Current userType:', userType);  // Add this to your Navbar component

    // Handle scroll effect
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768); // 768px is the md breakpoint
        };

        const handleScroll = () => {
            if (isMobile) return; // Don't handle scroll behavior on mobile

            const currentScrollY = window.scrollY;
            const scrollDifference = currentScrollY - lastScrollY.current;
            const scrollThreshold = 5; // Minimum scroll difference to trigger hide/show

            if (Math.abs(scrollDifference) > scrollThreshold) {
                // Show navbar when scrolling up or at top of page
                setIsVisible(scrollDifference < 0 || currentScrollY < 20);
                lastScrollY.current = currentScrollY;
            }
        };

        // Initial checks
        handleResize();

        // Add event listeners
        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll, {passive: true});

        // Cleanup
        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isMobile]);

    useEffect(() => {
        document.documentElement.setAttribute('data-theme', theme);
    }, [theme]);

    useEffect(() => {
        const handleUserTypeUpdate = (event) => {
            setUserType(event.detail.userType);
            setIsLoadingUserType(false);
        };

        window.addEventListener('userTypeUpdated', handleUserTypeUpdate);

        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            setUser(currentUser);
            setIsLoadingUserType(true);  // Start loading
            if (currentUser) {
                const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
                if (userDoc.exists()) {
                    setUserType(userDoc.data().userType);
                }
            } else {
                setUserType(null);
            }
            setIsLoadingUserType(false);  // End loading
        });

        return () => {
            window.removeEventListener('userTypeUpdated', handleUserTypeUpdate);
            unsubscribe();
        };
    }, []);

    // Close mobile menu when route changes
    useEffect(() => {
        setIsOpen(false);
    }, [location.pathname]);

    const handleSignOut = async () => {
        try {
            await signOut(auth);
            navigate('/');
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    const languages = {
        tr: {name: 'Türkçe', flag: '🇹🇷'},
        en: {name: 'English', flag: '🇬🇧'},
        ar: {name: 'العربية', flag: '🇸🇦'},
        de: {name: 'Deutsch', flag: '🇩🇪'}
    };

    const translations = {
        en: {
            systemTheme: "System Theme 💻",
            findBarber: "Find a Barber",
            createBarberShop: "Create Barber Shop",
            myAccount: "My Account",
            darkMode: "Dark Mode 🌙",
            lightMode: "Light Mode ☀️",
            signOut: "Sign Out",
            signIn: "Sign In",
            clientManagement: "Client Management",
            appointments: "My Appointments"
        },
        tr: {
            systemTheme: "Sistem Teması 💻",
            findBarber: "Berber Bul",
            createBarberShop: "Berber Dükkanı Oluştur",
            myAccount: "Hesabım",
            darkMode: "Karanlık Mod 🌙",
            lightMode: "Aydınlık Mod ☀️",
            signOut: "Çıkış Yap",
            signIn: "Giriş Yap",
            clientManagement: "Müşteri Yönetimi",
            appointments: "Randevularım"
        },
        ar: {
            systemTheme: "سمة النظام 💻",
            findBarber: "ابحث عن حلاق",
            createBarberShop: "إنشاء صالون حلاقة",
            myAccount: "حسابي",
            darkMode: "الوضع المظلم 🌙",
            lightMode: "الوضع المضيء ☀️",
            signOut: "تسجيل الخروج",
            signIn: "تسجيل الدخول",
            clientManagement: "إدارة العملاء",
            appointments: "مواعيدي"
        },
        de: {
            systemTheme: "System-Theme 💻",
            findBarber: "Friseur finden",
            createBarberShop: "Friseursalon erstellen",
            myAccount: "Mein Konto",
            darkMode: "Dunkelmodus 🌙",
            lightMode: "Hellmodus ☀️",
            signOut: "Abmelden",
            signIn: "Anmelden",
            clientManagement: "Kundenverwaltung",
            appointments: "Meine Termine"
        }
    };
    const t = translations[language];

    const getSystemTheme = () => {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            return 'luxury';  // Changed from 'dark' to 'luxury'
        }
        return 'emerald';
    };

// Create a listener for system theme changes
    useEffect(() => {
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        const handleChange = (e) => {
            if (theme === 'system') {
                document.documentElement.setAttribute(
                    'data-theme',
                    e.matches ? 'luxury' : 'emerald'  // Changed 'dark' to 'luxury'
                );
            }
        };

        // Updated to modern event listener syntax
        mediaQuery.addEventListener('change', handleChange);
        return () => mediaQuery.removeEventListener('change', handleChange);
    }, [theme]);

    const handleThemeChange = (newTheme) => {
        if (newTheme === 'system') {
            // Immediately check and apply system theme
            const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'luxury' : 'emerald';
            document.documentElement.setAttribute('data-theme', systemTheme);

            // Force a re-check by toggling the media query
            const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
            mediaQuery.dispatchEvent(new Event('change'));
        } else {
            document.documentElement.setAttribute('data-theme', newTheme);
        }
        toggleTheme(newTheme);
    };

// Update the useEffect for system theme to be more aggressive
    useEffect(() => {
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

        const handleChange = (e) => {
            if (theme === 'system') {
                const newTheme = e.matches ? 'luxury' : 'emerald';
                document.documentElement.setAttribute('data-theme', newTheme);
                // Force a DOM repaint
                // void document.body.offsetHeight;
                document.body.style.display = '';
            }
        };

        // Initial check on mount
        if (theme === 'system') {
            handleChange(mediaQuery);
        }

        mediaQuery.addListener(handleChange);
        return () => mediaQuery.removeListener(handleChange);
    }, [theme]);

    return (
        <nav
            id="main-navbar"
            className={`fixed top-0 w-full z-[100] transition-all duration-300 
                ${isScrolled ? 'bg-base-100/95 backdrop-blur-md shadow-md' : 'bg-base-100'}
                ${!isVisible && !isMobile ? '-translate-y-full' : 'translate-y-0'}`}
        >
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between items-center h-16">
                    {/* Logo */}
                    <Link to="/" className="flex items-center space-x-2 group">
                        <span
                            className="text-xl font-bold relative overflow-hidden group-hover:scale-105 transition-transform duration-300">
                            <span
                                className="inline-block animate-shimmer bg-gradient-to-r from-primary via-secondary to-primary bg-[length:200%_100%] bg-clip-text text-transparent font-extrabold tracking-tight">
                                Barber
                            </span>
                            <span
                                className="inline-block animate-pulse bg-gradient-to-r from-secondary to-primary bg-clip-text text-transparent font-black tracking-tight">
                                Buddy
                            </span>
                            <div
                                className="absolute bottom-0 left-0 w-full h-0.5 bg-gradient-to-r from-primary to-secondary transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"/>
                        </span>
                    </Link>


                    {/* Desktop Navigation */}
                    <div className="hidden md:flex items-center space-x-4">
                        <Link to="/shops" className="btn btn-ghost btn-sm rounded-full">
                            <Search className="w-4 h-4 mr-2"/>
                            {t.findBarber}
                        </Link>

                        <CustomerButtons user={user} userType={userType} t={t}/>
                        <ShopOwnerButtons user={user} userType={userType} t={t}/>
                        <NotificationButton user={user} userType={userType} theme={theme} />
                        <NavbarChatButton user={user} userType={userType} theme={theme} />

                        {/* Language Selector - Always visible */}
                        <div className="dropdown dropdown-end">
                            <label tabIndex={0} className="btn btn-ghost btn-sm rounded-full">
                                <Globe className="w-4 h-4 mr-2"/>
                                {languages[language].flag}
                                <ChevronDown className="w-4 h-4 ml-1"/>
                            </label>
                            <ul tabIndex={0}
                                className="dropdown-content menu shadow-lg bg-base-100 rounded-box w-52 mt-4">
                                {Object.entries(languages).map(([code, {name, flag}]) => (
                                    <li key={code}>
                                        <button
                                            onClick={() => changeLanguage(code)}
                                            className={`flex items-center space-x-2 ${
                                                language === code ? 'bg-primary/10 text-primary' : ''
                                            }`}
                                        >
                                            <span>{flag}</span>
                                            <span>{name}</span>
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        {/* Theme Selector - Always visible */}
                        <div className="dropdown dropdown-end">
                            <label tabIndex={0} className="btn btn-ghost btn-sm rounded-full">
                                {theme === 'emerald' ? (
                                    <Sun className="w-4 h-4"/>
                                ) : theme === 'luxury' ? (
                                    <Moon className="w-4 h-4"/>
                                ) : (
                                    <Monitor className="w-4 h-4"/>
                                )}
                                <ChevronDown className="w-4 h-4 ml-1"/>
                            </label>
                            <ul tabIndex={0}
                                className="dropdown-content menu shadow-lg bg-base-100 rounded-box w-48 mt-4">
                                <li>
                                    <button
                                        onClick={() => handleThemeChange('emerald')}
                                        className={`flex items-center space-x-2 ${
                                            theme === 'emerald' ? 'bg-primary/10 text-primary' : ''
                                        }`}
                                    >
                                        <Sun className="w-4 h-4"/>
                                        <span>{t.lightMode}</span>
                                    </button>
                                </li>
                                <li>
                                    <button
                                        onClick={() => handleThemeChange('luxury')}
                                        className={`flex items-center space-x-2 ${
                                            theme === 'luxury' ? 'bg-primary/10 text-primary' : ''
                                        }`}
                                    >
                                        <Moon className="w-4 h-4"/>
                                        <span>{t.darkMode}</span>
                                    </button>
                                </li>
                                <li>
                                    <button
                                        onClick={() => handleThemeChange('system')}
                                        className={`flex items-center space-x-2 ${
                                            theme === 'system' ? 'bg-primary/10 text-primary' : ''
                                        }`}
                                    >
                                        <Monitor className="w-4 h-4"/>
                                        <span>{t.systemTheme}</span>
                                    </button>
                                </li>
                            </ul>
                        </div>

                        {/* User Menu / Sign In */}
                        {user ? (
                            <div className="dropdown dropdown-end">
                                <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
                                    <div
                                        className="w-10 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                                        <img
                                            alt="User avatar"
                                            src={user.photoURL || "https://api.dicebear.com/7.x/avataaars/svg?seed=Felix"}
                                        />
                                    </div>
                                </label>
                                <ul tabIndex={0}
                                    className="dropdown-content menu shadow-lg bg-base-100 rounded-box w-52 mt-4">
                                    <li>
                                        <Link to="/account" className="flex items-center p-3 hover:bg-base-200">
                                            <User className="w-4 h-4 mr-2"/>
                                            {t.myAccount}
                                            <span className="badge badge-primary badge-sm ml-2">New</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <button
                                            onClick={handleSignOut}
                                            className="flex items-center p-3 text-error hover:bg-base-200"
                                        >
                                            <LogOut className="w-4 h-4 mr-2"/>
                                            {t.signOut}
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        ) : (
                            <Link
                                to="/auth"
                                className="btn btn-primary btn-sm rounded-full"
                            >
                                {t.signIn}
                            </Link>
                        )}
                    </div>

                    {/* Mobile menu button */}
                    <button
                        onClick={() => setIsOpen(!isOpen)}
                        className="md:hidden btn btn-ghost btn-circle"
                    >
                        {isOpen ? (
                            <X className="w-6 h-6"/>
                        ) : (
                            <Menu className="w-6 h-6"/>
                        )}
                    </button>
                </div>
            </div>

            {/* Mobile menu */
            }
            <div className={`md:hidden fixed top-16 left-0 right-0 transition-all duration-300 ease-in-out bg-base-100 shadow-lg ${
                isOpen ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-2 pointer-events-none'
            }`}>
                <div className="px-4 pt-2 pb-3 space-y-1">
                    <Link
                        to="/shops"
                        className="flex items-center p-3 rounded-lg hover:bg-base-200"
                    >
                        <Search className="w-4 h-4 mr-3"/>
                        {t.findBarber}
                    </Link>

                    <MobileCustomerButtons user={user} userType={userType} t={t}/>
                    <MobileShopOwnerButtons user={user} userType={userType} t={t}/>

                    {/* Notification and Chat Buttons Container */}
                    <div className="flex items-center justify-start gap-4 p-3">
                        <MobielNotificaiotn
                            user={user}
                            userType={userType}
                            theme={theme}
                            isMenuOpen={false} // Remove dependency on menu state
                        />

                        <MobileChatButton
                            user={user}
                            userType={userType}
                            theme={theme}
                            isMenuOpen={false} // Remove dependency on menu state
                        />
                    </div>

                    {/* Theme Switcher for Mobile */}
                    <div className="border-t border-base-200 pt-2 mt-2">
                        <p className="px-3 text-sm text-base-content/60">Theme Settings</p>
                        <div className="grid grid-cols-1 gap-1 p-2">
                            <button
                                onClick={() => handleThemeChange('emerald')}
                                className={`flex items-center space-x-3 p-3 rounded-lg ${
                                    theme === 'emerald' ? 'bg-primary/10 text-primary' : 'hover:bg-base-200'
                                }`}
                            >
                                <Sun className="w-4 h-4"/>
                                <span>{t.lightMode}</span>
                            </button>
                            <button
                                onClick={() => handleThemeChange('luxury')}
                                className={`flex items-center space-x-3 p-3 rounded-lg ${
                                    theme === 'luxury' ? 'bg-primary/10 text-primary' : 'hover:bg-base-200'
                                }`}
                            >
                                <Moon className="w-4 h-4"/>
                                <span>{t.darkMode}</span>
                            </button>
                            <button
                                onClick={() => handleThemeChange('system')}
                                className={`flex items-center space-x-3 p-3 rounded-lg ${
                                    theme === 'system' ? 'bg-primary/10 text-primary' : 'hover:bg-base-200'
                                }`}
                            >
                                <Monitor className="w-4 h-4"/>
                                <span>{t.systemTheme}</span>
                            </button>
                        </div>
                    </div>

                    {user ? (
                        <>
                            <Link
                                to="/account"
                                className="flex items-center p-3 rounded-lg hover:bg-base-200"
                            >
                                <User className="w-4 h-4 mr-3"/>
                                {t.myAccount}
                            </Link>
                            <button
                                onClick={handleSignOut}
                                className="flex w-full items-center p-3 rounded-lg hover:bg-base-200 text-error"
                            >
                                <LogOut className="w-4 h-4 mr-3"/>
                                {t.signOut}
                            </button>
                        </>
                    ) : (
                        <Link
                            to="/auth"
                            className="flex items-center p-3 rounded-lg bg-primary text-primary-content"
                        >
                            <User className="w-4 h-4 mr-3"/>
                            {t.signIn}
                        </Link>
                    )}

                    {/* Mobile Language Selector */}
                    <div className="border-t border-base-200 pt-2">
                        <p className="px-3 text-sm text-base-content/60">Select Language</p>
                        <div className="grid grid-cols-2 gap-2 p-2">
                            {Object.entries(languages).map(([code, {name, flag}]) => (
                                <button
                                    key={code}
                                    onClick={() => changeLanguage(code)}
                                    className={`flex items-center space-x-2 p-2 rounded-lg ${
                                        language === code ? 'bg-primary/10 text-primary' : 'hover:bg-base-200'
                                    }`}
                                >
                                    <span>{flag}</span>
                                    <span>{name}</span>
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;