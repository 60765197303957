import React, { useState, useEffect } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import BarberCalendar from './BarberCalendar';
import { X, Save, Clock } from 'lucide-react';

const EditAvailabilityModal = ({ shop, isOpen, onClose, onSave }) => {
    const [availability, setAvailability] = useState(shop.availability || {});
    const [specialDates, setSpecialDates] = useState(shop.specialDates || {});
    const [isLoading, setIsLoading] = useState(false);

    const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    // Check if a day exists in availability
    const isDayEnabled = (day) => {
        return availability[day] && availability[day].open && availability[day].close;
    };

    const handleTimeChange = (day, type, time) => {
        setAvailability(prev => ({
            ...prev,
            [day]: {
                ...prev[day],
                [type]: time
            }
        }));
    };

    const toggleDayOff = (day) => {
        setAvailability(prev => ({
            ...prev,
            [day]: isDayEnabled(day) ? null : { open: '09:00', close: '17:00' }
        }));
    };

    const handleSave = async () => {
        setIsLoading(true);
        try {
            // Filter out null values before saving
            const cleanedAvailability = Object.entries(availability).reduce((acc, [day, hours]) => {
                if (hours && hours.open && hours.close) {
                    acc[day] = hours;
                }
                return acc;
            }, {});

            const shopRef = doc(db, 'barberShops', shop.id);
            await updateDoc(shopRef, {
                availability: cleanedAvailability,
                specialDates
            });
            onSave({ ...shop, availability: cleanedAvailability, specialDates });
            onClose();
        } catch (error) {
            console.error('Error updating availability:', error);
        } finally {
            setIsLoading(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
            <div className="modal-box max-w-4xl w-full bg-base-100 rounded-lg shadow-xl">
                <div className="flex justify-between items-center p-4 border-b">
                    <h3 className="text-lg font-bold">Edit Availability</h3>
                    <button onClick={onClose} className="btn btn-ghost btn-sm btn-circle">
                        <X className="w-4 h-4" />
                    </button>
                </div>

                <div className="p-6 space-y-6">
                    <div className="space-y-4">
                        <h4 className="text-base font-semibold flex items-center gap-2">
                            <Clock className="w-4 h-4" />
                            Regular Hours
                        </h4>
                        <div className="grid gap-4">
                            {weekDays.map((day) => (
                                <div key={day} className="flex items-center gap-4">
                                    <div className="w-32">
                                        <label className="cursor-pointer label">
                                            <span className="label-text">{day}</span>
                                            <input
                                                type="checkbox"
                                                className="toggle toggle-primary"
                                                checked={isDayEnabled(day)}
                                                onChange={() => toggleDayOff(day)}
                                            />
                                        </label>
                                    </div>
                                    {isDayEnabled(day) && (
                                        <div className="flex gap-4 items-center flex-1">
                                            <select
                                                className="select select-bordered w-full"
                                                value={availability[day]?.open || '09:00'}
                                                onChange={(e) => handleTimeChange(day, 'open', e.target.value)}
                                            >
                                                {Array.from({ length: 24 }, (_, i) => i).map((hour) => (
                                                    <option key={hour} value={`${hour.toString().padStart(2, '0')}:00`}>
                                                        {`${hour.toString().padStart(2, '0')}:00`}
                                                    </option>
                                                ))}
                                            </select>
                                            <span>to</span>
                                            <select
                                                className="select select-bordered w-full"
                                                value={availability[day]?.close || '17:00'}
                                                onChange={(e) => handleTimeChange(day, 'close', e.target.value)}
                                            >
                                                {Array.from({ length: 24 }, (_, i) => i).map((hour) => (
                                                    <option key={hour} value={`${hour.toString().padStart(2, '0')}:00`}>
                                                        {`${hour.toString().padStart(2, '0')}:00`}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="space-y-4">
                        <h4 className="text-base font-semibold">Special Dates</h4>
                        <BarberCalendar
                            availability={availability}
                            specialDates={specialDates}
                            setSpecialDates={setSpecialDates}
                            t={{}}
                        />
                    </div>
                </div>

                <div className="modal-action p-4 border-t">
                    <button onClick={onClose} className="btn btn-ghost">
                        Cancel
                    </button>
                    <button
                        onClick={handleSave}
                        className="btn btn-primary"
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <span className="loading loading-spinner loading-sm" />
                        ) : (
                            <Save className="w-4 h-4 mr-2" />
                        )}
                        Save Changes
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EditAvailabilityModal;