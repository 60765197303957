import React, { useContext } from 'react';
import { Info, Scissors, Camera } from 'lucide-react';
import LanguageContext from "./LanguageContext";

const TabNavigation = ({ activeTab, setActiveTab }) => {
    const { language } = useContext(LanguageContext);

    const translations = {
        en: {
            about: "About",
            services: "Services",
            gallery: "Gallery"
        },
        tr: {
            about: "Hakkında",
            services: "Hizmetler",
            gallery: "Galeri"
        },
        ar: {
            about: "معلومات",
            services: "خدمات",
            gallery: "معرض"
        },
        de: {
            about: "Über",
            services: "Dienste",
            gallery: "Galerie"
        }
    };

    const t = translations[language];

    const tabs = [
        { id: 'about', icon: Info, label: t.about },
        { id: 'services', icon: Scissors, label: t.services },
        { id: 'gallery', icon: Camera, label: t.gallery }
    ];

    return (
        <div className="fixed bottom-6 left-1/2 -translate-x-1/2 z-50">
            <div className="flex gap-2 bg-base-100/80 p-2 rounded-full shadow-xl backdrop-blur-lg border border-base-200">
                {tabs.map(({ id, icon: Icon, label }) => (
                    <button
                        key={id}
                        onClick={() => setActiveTab(id)}
                        className={`group flex items-center gap-2 px-4 py-2 rounded-full transition-all duration-300
              ${activeTab === id
                            ? 'bg-primary text-primary-content shadow-lg scale-105'
                            : 'hover:bg-base-200 text-base-content'}`}
                    >
                        <Icon className={`w-5 h-5 ${activeTab === id ? '' : 'group-hover:text-primary'}`} />
                        <span className={`${activeTab === id ? 'opacity-100' : 'opacity-0 w-0'} 
              overflow-hidden transition-all duration-300 whitespace-nowrap
              ${activeTab === id ? 'max-w-24' : 'max-w-0'}`}>
              {label}
            </span>
                    </button>
                ))}
            </div>
        </div>
    );
};

export default TabNavigation;