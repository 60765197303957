import React, { useState, useRef, useEffect } from 'react';
import {
    ChevronRight, Calendar as CalIcon,
    Gift, Tag, Coffee, Star, AlertCircle,
    TrendingUp, Users, DollarSign, Clock,
    Calendar, ChevronLeft
} from 'lucide-react';

const BarberCalendar = ({ availability, specialDates, setSpecialDates, t }) => {
    const [selectedMonth, setSelectedMonth] = useState(new Date());
    const [isDragging, setIsDragging] = useState(false);
    const [dragStart, setDragStart] = useState(null);
    const [dragEnd, setDragEnd] = useState(null);
    const [selectedDates, setSelectedDates] = useState([]);
    const [editMode, setEditMode] = useState('regular');
    const [selectionFields, setSelectionFields] = useState([]);
    const calendarRef = useRef(null);
    const today = new Date();

    const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const modeColors = {
        regular: 'bg-primary/20 hover:bg-primary/30',
        holiday: 'bg-red-200 hover:bg-red-300',
        special: 'bg-yellow-200 hover:bg-yellow-300',
        promo: 'bg-green-200 hover:bg-green-300'
    };

    const modeIcons = {
        regular: CalIcon,
        holiday: Coffee,
        special: Star,
        promo: Tag
    };

    const getDaysInMonth = (date) => {
        const year = date.getFullYear();
        const month = date.getMonth();
        const firstDay = new Date(year, month, 1);
        const lastDay = new Date(year, month + 1, 0);
        const days = [];

        for (let i = 0; i < firstDay.getDay(); i++) {
            days.push(null);
        }

        for (let i = 1; i <= lastDay.getDate(); i++) {
            days.push(new Date(year, month, i));
        }

        return days;
    };

    const formatDate = (date) => {
        return date ? date.toISOString().split('T')[0] : '';
    };

    const isDateInRange = (date, start, end) => {
        if (!start || !end || !date) return false;
        const startTime = new Date(Math.min(start.getTime(), end.getTime()));
        const endTime = new Date(Math.max(start.getTime(), end.getTime()));
        return date >= startTime && date <= endTime;
    };

    const handleMouseDown = (date) => {
        if (!date || date < today) return;
        setIsDragging(true);
        setDragStart(date);
        setDragEnd(date);
    };

    const handleMouseMove = (date) => {
        if (isDragging && date) {
            setDragEnd(date);
        }
    };

    const handleMouseUp = () => {
        if (isDragging && dragStart && dragEnd) {
            const startDate = new Date(Math.min(dragStart.getTime(), dragEnd.getTime()));
            const endDate = new Date(Math.max(dragStart.getTime(), dragEnd.getTime()));

            // Check if any date in the range is already marked
            let isOverlapping = false;
            let overlappingType = null;

            for (const [existingStartDate, data] of Object.entries(specialDates || {})) {
                const existingStart = new Date(existingStartDate);
                const existingEnd = new Date(data.endDate);

                // Check if date ranges overlap
                if (startDate <= existingEnd && endDate >= existingStart) {
                    isOverlapping = true;
                    overlappingType = data.type;
                    break;
                }
            }

            if (!isOverlapping) {
                const newField = {
                    start: startDate,
                    end: endDate,
                    type: editMode
                };

                setSelectionFields(prev => [...prev, newField]);
                setSpecialDates(prev => ({
                    ...prev,
                    [formatDate(newField.start)]: {
                        type: newField.type,
                        endDate: formatDate(newField.end)
                    }
                }));
            }
        }
        setIsDragging(false);
        setDragStart(null);
        setDragEnd(null);
    };

    useEffect(() => {
        if (specialDates) {
            const fields = Object.entries(specialDates).map(([startDate, data]) => ({
                start: new Date(startDate),
                end: new Date(data.endDate),
                type: data.type
            }));
            setSelectionFields(fields);
        }
    }, []);

    const handleRemoveSelection = (index, field) => {
        // Remove from selectionFields
        setSelectionFields(prev => prev.filter((_, i) => i !== index));

        // Remove from specialDates
        setSpecialDates(prev => {
            const newDates = {...prev};
            delete newDates[formatDate(field.start)];
            return newDates;
        });
    };

    // Analytics calculations
    const getAnalytics = () => {
        const total = selectionFields.length;
        const byType = {
            holiday: selectionFields.filter(f => f.type === 'holiday').length,
            special: selectionFields.filter(f => f.type === 'special').length,
            promo: selectionFields.filter(f => f.type === 'promo').length
        };

        return { total, byType };
    };

    const analytics = getAnalytics();

    return (
        <div className="space-y-6 w-full max-w-4xl mx-auto">
            {/* Calendar Controls */}
            <div
                className="flex flex-col sm:flex-row justify-between items-center gap-4 bg-base-100 p-4 rounded-lg shadow-lg">
                <div className="flex items-center gap-4">
                    <button
                        className="btn btn-circle btn-sm"
                        onClick={() => {
                            // Only allow going forward, or back to current month
                            const newDate = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() - 1);
                            if (newDate.getMonth() >= today.getMonth() && newDate.getFullYear() >= today.getFullYear()) {
                                setSelectedMonth(newDate);
                            }
                        }}
                        disabled={selectedMonth.getMonth() === today.getMonth() && selectedMonth.getFullYear() === today.getFullYear()}
                    >
                        <ChevronLeft className="w-4 h-4"/>
                    </button>
                    <h3 className="text-xl font-bold">
                        {months[selectedMonth.getMonth()]} {selectedMonth.getFullYear()}
                    </h3>
                    <button
                        className="btn btn-circle btn-sm"
                        onClick={() => setSelectedMonth(new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1))}
                    >
                        <ChevronRight className="w-4 h-4"/>
                    </button>
                </div>

                <div className="join shadow-lg">
                    {Object.entries(modeIcons).map(([mode, Icon]) => (
                        <button
                            key={mode}
                            className={`join-item btn btn-sm gap-2 ${editMode === mode ? 'btn-primary' : 'btn-ghost'}
                transition-all duration-300 hover:scale-105`}
                            onClick={() => setEditMode(mode)}
                        >
                            <Icon className="w-4 h-4"/>
                            <span className="capitalize">{mode}</span>
                        </button>
                    ))}
                </div>
            </div>

            {/* Calendar Grid */}
            <div
                ref={calendarRef}
                className="select-none bg-base-100 rounded-lg shadow-xl p-4"
                onMouseLeave={handleMouseUp}
            >
                <div className="grid grid-cols-7 gap-2 mb-4">
                    {weekDays.map(day => (
                        <div key={day} className="text-center font-semibold text-gray-500">
                            {day}
                        </div>
                    ))}
                </div>

                <div className="grid grid-cols-7 gap-2">
                    {getDaysInMonth(selectedMonth).map((date, index) => {
                        const isInDragRange = isDragging && date && isDateInRange(date, dragStart, dragEnd);
                        const existingSelections = selectionFields.filter(field =>
                            date && isDateInRange(date, field.start, field.end)
                        );
                        const isPast = date && date < today;

                        return (
                            <div
                                key={index}
                                className={`
                  relative aspect-square flex items-center justify-center
                  transition-all duration-200 text-base
                  ${date ? 'cursor-pointer hover:scale-105' : ''}
                  ${isPast ? 'opacity-50 cursor-not-allowed' : ''}
                  ${isInDragRange ? modeColors[editMode] : ''}
                  ${existingSelections.length > 0 ? modeColors[existingSelections[0].type] : ''}
                  rounded-lg shadow-sm hover:shadow-md
                `}
                                onMouseDown={() => !isPast && handleMouseDown(date)}
                                onMouseMove={() => handleMouseMove(date)}
                                style={{
                                    animation: isInDragRange ? 'pulse 2s infinite' : 'none'
                                }}
                            >
                                {date && (
                                    <>
                    <span className={`
                      ${isPast ? 'text-gray-400' : ''}
                      ${existingSelections.length > 0 ? 'font-bold' : ''}
                    `}>
                      {date.getDate()}
                    </span>
                                        {existingSelections.length > 0 && (
                                            <div className="absolute -top-1 -right-1">
                                                {React.createElement(modeIcons[existingSelections[0].type], {
                                                    className: 'w-3 h-3'
                                                })}
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>

            {/* Analytics Dashboard */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                <div className="stat bg-base-100 rounded-lg shadow-lg p-4">
                    <div className="stat-figure text-primary">
                        <Calendar className="w-8 h-8" />
                    </div>
                    <div className="stat-title">Total Selections</div>
                    <div className="stat-value">{analytics.total}</div>
                </div>

                <div className="stat bg-base-100 rounded-lg shadow-lg p-4">
                    <div className="stat-figure text-red-500">
                        <Coffee className="w-8 h-8" />
                    </div>
                    <div className="stat-title">Holidays</div>
                    <div className="stat-value text-red-500">{analytics.byType.holiday}</div>
                </div>

                <div className="stat bg-base-100 rounded-lg shadow-lg p-4">
                    <div className="stat-figure text-yellow-500">
                        <Star className="w-8 h-8" />
                    </div>
                    <div className="stat-title">Special Days</div>
                    <div className="stat-value text-yellow-500">{analytics.byType.special}</div>
                </div>

                <div className="stat bg-base-100 rounded-lg shadow-lg p-4">
                    <div className="stat-figure text-green-500">
                        <Tag className="w-8 h-8" />
                    </div>
                    <div className="stat-title">Promo Days</div>
                    <div className="stat-value text-green-500">{analytics.byType.promo}</div>
                </div>
            </div>

            {/* Selection List */}
            {selectionFields.length > 0 && (
                <div className="bg-base-100 rounded-lg shadow-lg p-4">
                    <h3 className="text-lg font-bold mb-4">Selected Periods</h3>
                    <div className="space-y-2">
                        {selectionFields.map((field, index) => (
                            <div
                                key={index}
                                className={`
                  flex items-center justify-between p-2 rounded-lg
                  ${modeColors[field.type]}
                `}
                            >
                                <div className="flex items-center gap-2">
                                    {React.createElement(modeIcons[field.type], {className: 'w-4 h-4'})}
                                    <span>
                    {field.start.toLocaleDateString()} - {field.end.toLocaleDateString()}
                  </span>
                                </div>
                                <button
                                    className="btn btn-ghost btn-sm btn-circle"
                                    onClick={() => handleRemoveSelection(index, field)}
                                >
                                    ✕
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            <style jsx>{`
                @keyframes pulse {
          0% { transform: scale(1); }
          50% { transform: scale(1.05); }
          100% { transform: scale(1); }
        }
      `}</style>
        </div>
    );
};

export default BarberCalendar;